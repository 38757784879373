import Modal from 'react-bootstrap/Modal';
import { useEffect, useState } from "react";

import React, { Component } from 'react'
import { huntMyColor } from '../../../../Helper/ColorHuntData';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from '../../../../Redux/map';

class ThemeModal extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        primary: "var(--primary)",
        secondary: "var(--secondary)",
        accent: "var(--red)",
        supporting: "var(--supporting)"
    };




    // defaultHandleClick = () => {


    //     document.documentElement.style.setProperty('--red', `#ec412c`);
    //     document.documentElement.style.setProperty('--primary', `#1F2122`);
    //     document.documentElement.style.setProperty('--secondary', `#27292A`);
    //     document.documentElement.style.setProperty('--supporting', `#ffffff`);

    // }


    customHandleClick = () => {


        document.documentElement.style.setProperty('--red', this.state.accent);
        document.documentElement.style.setProperty('--primary', this.state.primary);
        document.documentElement.style.setProperty('--secondary', this.state.secondary);
        document.documentElement.style.setProperty('--supporting', `#ffffff`);
        console.log(this.state);
        localStorage.setItem("CustomTheme", JSON.stringify(this.state));

    }


    handleClick = () => {
        this.setState({
            colorPalette: huntMyColor()
        });

        document.documentElement.style.setProperty('--red', `#${this.state.colorPalette.c3}`);
        document.documentElement.style.setProperty('--primary', `#${this.state.colorPalette.c2}`);
        document.documentElement.style.setProperty('--secondary', `#${this.state.colorPalette.c3}`);
        document.documentElement.style.setProperty('--supporting', `#${this.state.colorPalette.c2}`);

    }

    // onHide() {
    //     ;
    // }
    render() {
        return (
            <Modal

                contentClassName='game-modal rounded-5'
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.props.themeModal}
                onHide={() => {
                    this.props.SetThemeModal(false);
                }}

            >
                {/* <Modal.Header closeButton></Modal.Header> */}

                <Modal.Body className='rounded-3 p-5'>
                    <div className='row'>
                        <h2 className='text-center mb-4'> <u style={{ color: "var(--red)" }}>Custom</u> Theme</h2>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className="form-group text-white mb-4">
                                <label data-testid="gameTitle" htmlFor='gameTitle'>Primary Color</label>
                                <input type="color" value={this.state.primary} className='form-control p-0 mt-3' onChange={(event) => {
                                    this.setState({
                                        primary: event.target.value
                                    })
                                }} />
                            </div>
                            <div className="form-group text-white mb-4">
                                <label data-testid="gameTitle" htmlFor='gameTitle'>Secondary Color</label>
                                <input type="color" className='form-control p-0 mt-3' onChange={(event) => {
                                    this.setState({
                                        secondary: event.target.value
                                    })
                                }} />
                            </div>
                            <div className="form-group text-white mb-4">
                                <label data-testid="gameTitle">Accent Color</label>
                                <input type="color" className='form-control p-0 mt-3' onChange={(event) => {
                                    this.setState({
                                        accent: event.target.value
                                    })
                                }} />
                            </div>

                            <button className='btn form-control' onClick={this.customHandleClick}>Create My Theme</button>

                        </div>
                        <div className='col-lg-8'>
                            <div id='primary' className='rounded-5 position-relative' style={{ height: "400px", backgroundColor: `${this.state.primary}`, border: "whitesmoke 2px solid" }}>
                                <div className='dropdown-accent' style={{ backgroundColor: `${this.state.accent}` }}></div>
                                <div className=' rounded-5 rounded-bottom position-absolute bottom-0' style={{ height: "350px", width: "390px", marginLeft: "35px", backgroundColor: `${this.state.secondary}` }}>
                                    <div id='secondary' className=' rounded-5 rounded-bottom w-75' style={{ height: "300px", backgroundColor: `${this.state.primary}` }}>

                                        <div className='dropdown-accent' style={{ backgroundColor: `${this.state.accent}`, borderRadius: "5px" }}></div>

                                        <div className='row mt-5 me-1 ms-2'>
                                            <div id='card' className=' rounded-4 ms-4' style={{ height: "150px", width: "35%", backgroundColor: `${this.state.secondary}` }}></div>
                                            <div id='card' className=' rounded-4 ms-4' style={{ height: "150px", width: "35%", backgroundColor: `${this.state.secondary}` }}></div>
                                        </div>

                                        <div className='row mt-4 me-1 ms-2'>
                                            <div id='card' className='rounded-4 rounded-bottom ms-4' style={{ height: "76px", width: "35%", backgroundColor: `${this.state.secondary}` }}></div>
                                            <div id='card' className=' rounded-4 rounded-bottom ms-4' style={{ height: "76px", width: "35%", backgroundColor: `${this.state.secondary}` }}></div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal >
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThemeModal);


// export default function ThemeModal(props) {

//     useEffect(() => {
//         window.scrollTo(0, 0);
//     }, []);


//     const OnHideModal = () => {
//         props.handleClose();
//     }



//     return (
//         <Modal

//             contentClassName='game-modal rounded-5'
//             {...props}
//             // size="lg"
//             aria-labelledby="contained-modal-title-vcenter"
//             centered
//             show={props.show}
//             onHide={OnHideModal()}
//         >

//             <Modal.Body className='rounded-3 p-5'>



//             </Modal.Body>
//         </Modal >

//     );



// }