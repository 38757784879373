
import React, { useState, useEffect } from 'react';
import { PanelAPI } from '../../API/Endpoints/Backend/PanelAPI';
import FullWidthTabs from './Components/Tab';

import "./Panel.css"
import GamesGrid from '../Home/Components/GamesGrid';
import { PanelDeleteModal } from './Components/PanelDeleteModal';
import { AbbreviateNumber } from '../../Helper/Helper';

const MyPanel = (props) => {
    const [panelGames, setpanelGames] = useState([]);
    const [myPanel, setmyPanel] = useState(null);
    const [panelId, setpanelId] = useState(null);
    const [panelToModify, setPanelToModify] = useState(null);
    const [showModal, setModalShow] = useState(null);
    //   showModal: false,

    const fetchGamesByPanelId = () => {
        PanelAPI.getMyPanel(panelId, 0, 1)
            .then((Games) => {
                if (Games != null) {
                    console.log(Games);
                    setpanelGames(Games)
                }
            })

    }

    const OnEditClick = (pnl) => {
        console.log(pnl);
        props.history.push({
            pathname: '/createpanel',
            state: { panelToModify: pnl, isModify: true },
        });

    }

    function OnDeleteClick(panel) {
        setPanelToModify(panel);
        setModalShow(true);

        handleShowModal();

    }



    function handleShowModal() {
        setModalShow(true);
        // this.setState({ showModal: true });
    };

    function handleCloseModal() {
        setModalShow(false);
        // this.setState({ showModal: false });
    };

    function handleSaveModal() {
        // Add your save logic here
        setModalShow(false);
        // this.setState({ showModal: false });
    };





    useEffect(() => {
        PanelAPI.getMyPanel()
            .then((json) => {
                setmyPanel(json);
                // setpanelId(myPanel.panelId);
                console.log(json);
            })
            .catch(() => {
                props.history.push('/');
            })
    }, [])

    return (
        <>
            {
                (panelToModify != null) ?
                    <>

                        <PanelDeleteModal
                            show={showModal}
                            handleClose={handleCloseModal}
                            handleSave={handleSaveModal}
                            panel={panelToModify}
                            {...props}
                        />



                    </>
                    : null
            }
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="page-content shadow-bg">
                            <div class="row">
                                <div class="col-lg-12 mt-5 mt-sm-5 mt-md-0 mt-lg-0 mt-xl-0 mt-xxl-0">
                                    <div class="main-profile shadow-bg">

                                        {
                                            (myPanel != null) ?
                                                <div style={{ backgroundImage: "url(" + myPanel.coverImageUrl + ")" }} className="main-banner mb-4 d-none d-lg-block">
                                                </div>
                                                : null
                                        }

                                        <div class="row">
                                            <div class="col-lg-4 align-self-center">

                                                {
                                                    (myPanel != null) ?
                                                        <img src={myPanel.profileImageUrl} className='panel-profile-pic' />
                                                        : null
                                                }

                                            </div>
                                            <div class="col-lg-4 align-self-center text-lg-left text-center">
                                                <div class="main-info header-text">

                                                    {
                                                        (myPanel != null) ? <div>

                                                            <h4>{myPanel.name}</h4>
                                                            <i onClick={event => OnDeleteClick(myPanel)} className="fa fa-trash mx-2 text-danger"></i>
                                                            <i onClick={event => OnEditClick(myPanel)} className="fa fa-edit text-primary"></i>

                                                            {/* onClick={event => this.OnDeleteClick(game)} */}

                                                            <p className='hover-me'>{myPanel.panelDescription}</p>
                                                        </div> : null
                                                    }

                                                    {/* <div className="main-button mt-3">
                                                    <a href="http://localhost:3000/browse">Jahanzaib</a>
                                                </div> */}
                                                </div>
                                            </div>
                                            <div class="col-lg-4  align-self-center">

                                                {
                                                    (myPanel != null) ?
                                                        <ul>
                                                            {/* <li>Total Games <span>{myPanel.length}</span></li> */}
                                                            <li>Total Likes<span> {AbbreviateNumber(myPanel.panelTotalLikes
                                                            )}</span></li>
                                                            <li>Total Plays <span>{AbbreviateNumber(myPanel.panelTotalLikes)}</span></li>
                                                            <li>Total Playtime <span>None</span></li>
                                                        </ul>

                                                        : null
                                                }

                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>


                            {
                                (myPanel != null) ?
                                    <GamesGrid
                                        gridType="rds"
                                        rdsType="PanelGames"
                                        isDev={true}
                                        {...props}
                                    />
                                    // <div>
                                    //     <h4><FullWidthTabs authorized={1} panelId={myPanel.panelId} /></h4>

                                    // </div> 
                                    : null
                            }




                        </div>
                    </div>
                </div>
            </div >
        </>



    );

}
export default MyPanel;