// Spring & React URLS
// export const API_BASE_URL = 'http://localhost:8080';
// export const React_Base_URL = 'http://localhost:3000';
// export const API_BASE_URL = 'http://playtube.ap-northeast-1.elasticbeanstalk.com';
export const API_BASE_URL = 'https://elb.playtube.cloud';
export const React_Base_URL = 'https://playtube.cloud';


//RAWG API Constants
export const RAWG_API_KEY = 'eae3abaa558a4e4cbdcd84cbe2c2ad78';
export const RAWG_API_URL = 'https://api.rawg.io/api';


//Social Redirection URLS
export const OAUTH2_REDIRECT_URI = React_Base_URL + '/oauth2/redirect';
export const GOOGLE_AUTH_URL = API_BASE_URL + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL = API_BASE_URL + '/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const GITHUB_AUTH_URL = API_BASE_URL + '/oauth2/authorize/github?redirect_uri=' + OAUTH2_REDIRECT_URI;

//Accesstoken
export const ACCESS_TOKEN = 'accessToken';



