import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import convert from 'htmr';
import { Helmet } from "react-helmet";

import { LikesAPI } from '../../API/Endpoints/Backend/LikesAPI';
import { PlayAPI } from '../../API/Endpoints/Backend/PlayAPI';
import { InteractionsAPI } from '../../API/Endpoints/Recombee/InteractionsAPI';

// import DefaultComponent from "../Home/Components/CommentSection";
import Image_Viewer from '../Home/Components/Image-Viewer';

import "./gameview.css";
import { GameAPI } from '../../API/Endpoints/Backend/GameAPI';
import GameShareModal from './Components/GameShare';
import { React_Base_URL } from '../../API/Constants';
import GamesGrid from '../Home/Components/GamesGrid';
import { AbbreviateNumber, checkDeviceOS } from '../../Helper/Helper';


import { useLocation } from 'react-router-dom';

const GameView = (props) => {

  const playCountTimer = useState(30);

  const { gameId } = props.match.params;
  const [game, setGame] = useState(null);

  const [modalShow, setModalShow] = useState(false);
  const [modalGame, setModalGame] = useState(null);
  const history = useHistory();


  const { state } = useLocation();
  const recommendationId = state?.recommendationId;



  const makeFullScreen = (e) => {

    console.log(checkDeviceOS());

    if (
      checkDeviceOS() == "MACOS" ||
      checkDeviceOS() == "IOS"
      // || checkDeviceOS() == "Windows"
    ) {

      // Redirect to the new route with props
      history.push('/gameplay', { gameSource: game.source });
    }
    else {
      // document.getElementsByTagName("iframe")[0].className = "fullscreen";
      var element = document.getElementsByTagName("iframe")[0];

      var requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullscreen;

      if (requestMethod) { // Native full screen.
        requestMethod.call(element);
      }
    }


    // Create the swap icon element
    const swapIcon = document.createElement("i");
    swapIcon.className = "fas fa-exchange-alt swap-icon";

    // Add a click event listener to the swap icon
    swapIcon.addEventListener("click", () => {
      // Handle swap icon click event here
      alert("Swap icon clicked!");
    });

    // Append the swap icon to the nested iframe
    const nestedIframe = document.getElementById("game_drop");
    nestedIframe.contentDocument.body.appendChild(swapIcon);

    // Listen for fullscreen change events
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    // Function to handle fullscreen change events
    function handleFullscreenChange() {
      const isFullscreen =
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement;

      // Show or hide the swap icon based on the fullscreen status
      if (isFullscreen) {
        swapIcon.classList.add("visible");
      } else {
        swapIcon.classList.remove("visible");
      }
    }


    window.screen.orientation.lock("landscape");
  }


  //PlayAPI Calls
  const playCount = () => {

    if (props.authenticated) {

      console.log("Play Counted")
      console.log("Interaction => Cart Addition");

      InteractionsAPI.addCartAddition(gameId);
      PlayAPI.play(gameId);
    }
  }

  const playCountExist = () => {
    if (props.authenticated) {
      PlayAPI.exist(gameId).then((response) => {
        console.log(response);
        if (response.data) {
          console.log("Existing Play Count");
        }
        else {
          playCount();
        }
      });
    }

  }

  // Like API Calls
  const like = () => {

    if (props.authenticated) {
      console.log("Liked")



      console.log("Interaction => AddRating");


      // LikesAPI.like(game.gameId);
      // InteractionsAPI.addRating(game.gameId);

      LikesAPI.like(gameId);
      InteractionsAPI.addRating(gameId);

      document.getElementById('like').classList.remove("text-secondary")
      document.getElementById('like').onclick = unlike;


    }
  }

  const unlike = () => {
    if (props.authenticated) {
      console.log("UnLiked")

      LikesAPI.unlike(gameId);

      document.getElementById('like').classList.add("text-secondary")
      document.getElementById('like').onclick = like;
    }
  }


  const likeExist = () => {
    console.log(props.authenticated);
    if (props.authenticated) {
      LikesAPI.exist(gameId).then((response) => {
        console.log(response);
        if (response.data) {

          document.getElementById('like').classList.remove("text-secondary")
          document.getElementById('like').onclick = unlike;


        }
        else {

          document.getElementById('like').classList.add("text-secondary")
          document.getElementById('like').onclick = like;

        }
      });
    }
    else {
      document.getElementById('like').classList.add("text-secondary")
      document.getElementById('like').onclick = like;
    }

  }


  const schedule = () => {
    console.log('Scheduled in ' + playCountTimer);
    const timerId = setTimeout(() => {
      playCountExist();
    },
      playCountTimer * 1000);

    return timerId;
  }

  useEffect(() => {
    GameAPI.get(gameId).then((game) => {
      console.log(game);
      setGame(game);

      likeExist();

      // if (props.authenticated) {
      InteractionsAPI.addDetailView(gameId);
      // }

      const timerId = schedule();
      return () => clearTimeout(timerId);

    });

  }, []);





  return (
    <>

      {
        (game != null) ?
          <>
            <Helmet>
              <meta http-equiv="Content-Security-Policy" content="gamepad 'self'" />
            </Helmet>
            <GameShareModal
              game={game}
              show={modalShow}
              onHide={() => setModalShow(false)}
              {...props}

            />
            <div class="container slide-in-bottom slide-in-bottom">
              <div class="row">
                <div class="col-lg-12">
                  <div class="page-content shadow-bg">

                    <div class="row">
                      <div class="col-lg-12">
                        <div class="feature-banner header-text">
                          <div class="row d-flex text-center">

                            <div className='videoWrapper'>


                              <iframe
                                className=' rounded-5 shadow-bg'
                                frameborder="0"
                                allowfullscreen="true" scrolling="no"
                                allow="autoplay; fullscreen *; geolocation; microphone; camera; midi; monetization; xr-spatial-tracking;"
                                // allow="autoplay; fullscreen *; geolocation; microphone; camera; midi; monetization; xr-spatial-tracking; gamepad; gyroscope; accelerometer; xr; cross-origin-isolated"
                                allowtransparency="true" webkitallowfullscreen="true" mozallowfullscreen="true"
                                msallowfullscreen="true"
                                sandbox


                                src={game.source}
                                // src="https://v6p9d9t4.ssl.hwcdn.net/html/2726184/index.html"
                                id="game_drop">


                              </iframe>
                              {/* <i className='fas fa-exchange-alt swap-icon text-white'></i> */}
                            </div>


                          </div>
                        </div>
                      </div>
                    </div>



                    {/* <!-- ***** Details Start ***** --> */}
                    <div class="game-details mt-4">
                      <div class="row">

                        <div class="col-lg-12">
                          <div class="content shadow-bg">
                            <div class="row">
                              <div class="col-lg-6">

                                <div class="left-info shadow-bg">
                                  <div className='row'>
                                    <div className='col'>
                                      <div class="text-center">
                                        <h4>{game.gameTitle}</h4>
                                        <span>{game.genreName}</span>

                                      </div>
                                    </div>
                                    <div className='col-4'>
                                      <i onClick={event => makeFullScreen(event)} class="fa fa-2x fa-expand text-white cursor-pointer"></i>
                                    </div>

                                  </div>
                                </div>


                              </div>

                              <div class="col-lg-6">
                                <div class="right-info shadow-bg">
                                  <ul>
                                    <li><i id='like' class="fa fa-star"></i> {AbbreviateNumber(game.gameTotalLikes)}</li>
                                    <li><i id='play' class="fa fa-play" style={{ color: "var(--red)" }}></i> {AbbreviateNumber(game.gameTotalPlays)}</li>
                                    <li><i class="fa fa-plus" style={{ color: "skyblue" }}></i>Add</li>
                                    <li><i id='share' onClick={() => {
                                      // setModalGameLink(React_Base_URL + "/game/" + gameId);
                                      setModalShow(true);
                                      setModalGame(game);

                                      console.log("krlia");
                                    }} class="fa fa-share" style={{ color: "lightgreen" }}></i> Share</li>

                                  </ul>
                                </div>
                              </div>
                            </div>

                            <div className='row secondary-bg rounded-4 p-3 shadow-bg'>
                              <h3 className='mt-3 mb-1 red-font text-center'>Description</h3>
                              <p className='text-white text-center'> {convert(decodeURI(game.gameDescription))}</p>
                              <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
                                {game.gameTagList.map(tag => (
                                  <div key={tag.tag.tagId} className='text-white m-2 p-2 rounded-3 tag-style'>{tag.tag.tagName}</div>
                                ))}
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>


                    {
                      (game.gameScreenShotsList.length != 0 && game.gameScreenShotsList !== undefined && game.gameScreenShotsList !== null) ?
                        <div class="game-details text-white mt-4">
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="content shadow-bg">
                                <div class="row">
                                  <h3 className='mt-3 mb-3 red-font text-center'>Screenshots</h3>

                                  <Image_Viewer images={game.gameScreenShotList} />


                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        : null
                    }
                    {/* <GameGrid
                      gridType="recommendation"
                      recommendationType="Recommended"
                      recommendGameId={gameId}

                      // genre="Action"
                      {...this.props} /> */}
                    <GamesGrid
                      gridType="recommendation"
                      recommendationType="Similar"
                      recommendGameId={gameId}
                      // genre="Action"
                      {...props} />



                    {/* <div class="game-details text-white mt-4">
                      <div class="row">

                        <div class="col-lg-12">
                          <div class="content shadow-bg">
                            <div class="row">
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}



                  </div>
                </div>
              </div>
            </div>
          </>
          :
          <p>Loading...</p>
      }

    </>
  )
}

export default GameView;