import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter, BrowserRouter as Router } from 'react-router-dom';

import { Provider } from 'react-redux';
import store from './Redux/store';

import "@fontsource/poppins";
import './index.css';



import "./assets/vendor/bootstrap/css/bootstrap.min.css";
// <!-- Additional CSS Files -->
import "./assets/css/fontawesome.css";
import "./assets/css/main.css";
import "./assets/css/animate.css";

// <!-- Font Icon -->
import "./assets/fonts/material-icon/css/material-design-iconic-font.min.css";

import App from "./Views/App/App";


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode >,
  document.getElementById('root')
);

registerServiceWorker();
