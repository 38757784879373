import React, { Component } from 'react';


import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Container from 'react-bootstrap/Container';

import { React_Base_URL } from '../../API/Constants';
import profilepic from "../../assets/img/profile-header.jpg";

import "./AppHeader.css"
import { huntMyColor } from '../../Helper/ColorHuntData';
import { Link } from 'react-router-dom';
import ThemeModal from '../Home/Components/ColorPicker/ThemeModal';
import { mapDispatchToProps, mapStateToProps } from '../../Redux/map';
import { connect } from 'react-redux';

class AppHeader extends Component {

  constructor(props) {
    super(props);
    // this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  state = {
    gameSearched: [],
    DataisLoaded: false,
    searchText: null,
    // modal: false,
    colorPalette: null,
    modal: false
  };


  handleInputChange = event => {
    this.props.onSearched(event.target.value);
  }




  defaultHandleClick = () => {


    document.documentElement.style.setProperty('--red', `#ec412c`);
    document.documentElement.style.setProperty('--primary', `#1F2122`);
    document.documentElement.style.setProperty('--secondary', `#27292A`);
    document.documentElement.style.setProperty('--supporting', `#ffffff`);
    localStorage.removeItem("CustomTheme");


  }

  randomHandleClick = () => {
    this.setState({
      colorPalette: huntMyColor()
    });

    document.documentElement.style.setProperty('--red', `#${this.state.colorPalette.c1}`);
    document.documentElement.style.setProperty('--primary', `#${this.state.colorPalette.c2}`);
    document.documentElement.style.setProperty('--secondary', `#${this.state.colorPalette.c3}`);
    document.documentElement.style.setProperty('--supporting', `#${this.state.colorPalette.c2}`);

    var data = {
      primary: this.state.colorPalette.c2,
      secondary: this.state.colorPalette.c3,
      accent: this.state.colorPalette.c3,
      supporting: this.state.colorPalette.c3
    };

    localStorage.setItem("CustomTheme", JSON.stringify(data));

  }


  customHandleClick = () => {
    this.handleShowModal();
  }

  handleShowModal() {
    this.props.SetThemeModal(true)
    // this.setState({
    //   modal: true
    // })
  };

  // handleCloseModal = () => {
  //   this.state.modal = false;
  //   // this.setState({
  //   //   modal: false
  //   // })
  // };

  // handleSaveModal() {
  //   this.setState({
  //     modal: false
  //   })
  // };



  SplitBasicExample() {
    return (
      <Dropdown >
        <Dropdown.Toggle variant='secondary' className='btn-panel hover-me' id="dropdown-basic">
          {
            this.props.authenticated ? (
              <span>{this.props.currentUser.userName}
                {
                  (this.props.currentUser.imageUrl) ?
                    <img class='app-header-img' src={this.props.currentUser.imageUrl} alt="" srcset="" />
                    :
                    <img class='app-header-img' src={profilepic} alt="" srcset="" />

                }
              </span>
            ) : (
              <span>Panel<img class='app-header-img' src={profilepic} alt="" srcset="" /></span>
            )
          }
        </Dropdown.Toggle>

        {
          this.props.authenticated ? (
            <Dropdown.Menu variant='dark' className='dropdown-opener' >
              <Dropdown.Item disabled>
                {
                  (this.props.currentUser.imageUrl) ?
                    <img className='me-2 app-header-img' src={this.props.currentUser.imageUrl} alt="" srcset="" />
                    :
                    <img className='me-2 app-header-img' src={profilepic} alt="" srcset="" />
                }
                {this.props.currentUser.userName}
              </Dropdown.Item>
              <hr />
              {
                (this.props.currentUser.roleId == 3) ?
                  <>
                    <Dropdown.Item href="/mypanel">Panel</Dropdown.Item>
                    <Dropdown.Item href="/upload-game">Upload New Game</Dropdown.Item>
                  </>
                  :
                  <Dropdown.Item href="/createpanel">Create Your Panel</Dropdown.Item>
              }
              <hr />
              <Dropdown.Item><a onClick={this.props.onLogout}>Logout</a></Dropdown.Item>
            </Dropdown.Menu>
          )
            :
            (
              <Dropdown.Menu variant='dark' className='dropdown-opener'>

                <Dropdown.Item className='text-center' disabled>

                  <img src="images/logo.png" alt="" />

                </Dropdown.Item>

                <hr />
                <Dropdown.Item className='text-center'> <Link to="/">Home</Link></Dropdown.Item>
                <Dropdown.Item className='text-center'><Link to="/browser">Browse</Link></Dropdown.Item>


                <hr />
                <Dropdown.Item className='text-center'> <Link to="/login">Login</Link></Dropdown.Item>
                <Dropdown.Item className='text-center'><Link to="/signup">Signup</Link></Dropdown.Item>

              </Dropdown.Menu>
            )

        }


      </Dropdown >
    );
  }

  render() {
    // console.log(this.props.currentUser);
    return (
      <>
        <ThemeModal
        // show={this.props.themeModal}
        // handleclose={this.handleCloseModal}
        // handleSave={this.handleSaveModal}
        // panel={panelToModify}
        />
        {/* // <!-- ***** Header Area Start ***** --> */}
        <Navbar fixed='top' key="lg" expand="lg" variant='dark' className="mb-3 primary-bg">
          <Container className='text-white mt-2 mb-1'>

            <Navbar.Brand>
              <a href={React_Base_URL} className="logo hover-me">
                <img src="images/logo.png" alt="" />
              </a>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />

            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-lg`}
              aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                  <a href={React_Base_URL} className="logo hover-me">
                    <img src="images/logo.png" alt="" />
                  </a>

                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>

                <form id="search" action="/browser" method='get'>
                  <input style={{ backgroundColor: "var(--secondary)" }} className='mt-1 shadow-bg text-white hover-me red-bg' type="text" value={this.props.searchTerm} onChange={event => this.handleInputChange(event)} placeholder="Search Anything" id='searchText' name="search"
                    onkeypress="handle" />

                  <i className='fa-regular fa-moon ms-3 mt-3 hover-me semi-translucent-fg' style={{ fontSize: "22px" }} onClick={this.defaultHandleClick}   ></i>
                  <i className='fa-solid fa-random hover-me ms-5 mt-3 semi-translucent-fg' style={{ fontSize: "22px" }} onClick={this.randomHandleClick}></i>
                  <i class="fa-solid fa-paint-roller mt-3 hover-me semi-translucent-fg" style={{ fontSize: "20px", marginLeft: "85px" }} onClick={this.customHandleClick}></i>

                </form>

                {this.props.authenticated ? (
                  <Nav className="justify-content-end flex-grow-1 pe-3 text-white">
                    {/* <i className='fa-regular fa-moon pe-3 me-1'></i> */}

                    <hr />
                    <Nav.Link className='text-white-50 me-1 hover-me' onClick={this.props.onLogout}>Logout</Nav.Link>
                    <hr />
                    {/* // <a onClick={this.props.onLogout}>Logout</a> */}
                    {this.SplitBasicExample()}
                  </Nav>

                ) : (


                  <Nav className="justify-content-end flex-grow-1 pe-3 ">
                    <hr />
                    <Nav.Link className='text-white-50 hover-me' href='/browser' >Browse</Nav.Link>
                    <Nav.Link className='text-white-50 me-1 hover-me' href='/login' >Login </Nav.Link>
                    <hr />
                    {this.SplitBasicExample()}
                  </Nav>

                )}

              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar >

      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);