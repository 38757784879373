import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { useDispatch } from 'react-redux';
import { setActiveGameTag } from '../../../Redux/actions';


import "../Home.css";
import { ThemeProvider } from '@mui/material';
import { tabTtheme } from './MUIStyles';
import { GameTagRecommendationAPI } from '../../../API/Endpoints/Recombee/GameTagRecommendationAPI';

export default function TagTab(props) {
    const [value, setValue] = React.useState("All");
    const [tagsCount, setTagsCount] = React.useState(10);

    const [tagRecommendId, setTagRecommendId] = React.useState(null);
    const [tagList, setTagList] = React.useState([]);

    const dispatch = useDispatch();

    const handleChange = (event, newValue) => {
        setValue(newValue);
        dispatch(setActiveGameTag(newValue));
        props.ontabClick(newValue);

    };

    React.useEffect(() => {
        GameTagRecommendationAPI.recommendGameTags(tagsCount)
            .then((res) => {
                console.log(res);
                setTagRecommendId(res.recommendId)
                setTagList(res.recommendedItems);
            });
    }, [])


    return (
        <div className='slide-in-bottom-item shadow-bg' style={{ borderRadius: "15px" }}>
            <ThemeProvider theme={tabTtheme}>
                <Box display="flex" justifyContent="center" width="100%" sx={{ bgcolor: 'var(--primary)', borderRadius: "15px", marginTop: "30px", alignItems: "center", justifyContent: "center" }}>
                    <Tabs
                        className="my-tabs"
                        style={{
                            fontSize: "40",
                            color: "var(--red)"
                        }}
                        value={value}
                        onChange={handleChange}

                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="visible arrows tabs example"

                    >
                        <Tab value="All" label="All" />
                        {
                            tagList.map(tag => (
                                <Tab key={tag} value={tag} label={tag} />
                            ))
                        }

                    </Tabs>
                </Box>
            </ThemeProvider >
        </div>
    );
}