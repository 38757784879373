// src/redux/actions.js

export function setActiveGameTag(tag) {
    return { type: 'SET_ACTIVE_GAME_TAG', payload: tag };
}


export function setTagGames(games) {
    return {
        type: 'SET_TAG_GAMES',
        payload: games,
    };
}


export function setThemeModal(themeModal) {
    return {
        type: 'SET_THEME_MODAL',
        payload: themeModal,
    };
}