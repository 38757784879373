import React, { Component } from 'react';
import Alert from 'react-s-alert';

import { GOOGLE_AUTH_URL, FACEBOOK_AUTH_URL, GITHUB_AUTH_URL, ACCESS_TOKEN, API_BASE_URL } from '../../API/Constants';
import { Redirect } from 'react-router-dom'

import "../Upload/UploadGame.css";
import '../Profile/login/Login.css';
import 'react-circular-progressbar/dist/styles.css';
import { PanelAPI } from '../../API/Endpoints/Backend/PanelAPI';

class CreatePanel extends Component {
    render() {


        return (
            <div className="login-container appheader-margin">
                <div className="login-content login-card">
                    <h1 className="login-title">Create Your<span style={{ color: "#E13536" }}> Panel</span></h1>
                    <LoginForm {...this.props} />
                </div>
            </div>
        );
    }
}

class SocialLogin extends Component {
    render() {
        return (
            <div class="mt-4">
                <label className='text-white mb-4'>Or login with</label>
                <ul>
                    <li><a href={FACEBOOK_AUTH_URL}>
                        <div className='facebook-btn'>
                            <i class="zmdi zmdi-facebook zmdi-hc-3x"></i>
                        </div>

                    </a></li>
                    <li><a href={GITHUB_AUTH_URL}> <div className='github-btn'>
                        <i class="zmdi zmdi-github zmdi-hc-3x"></i>
                    </div></a></li>
                    <li><a href={GOOGLE_AUTH_URL}><div className='google-btn'>
                        <i class="zmdi zmdi-google zmdi-hc-3x"></i>
                    </div></a></li>
                </ul>
            </div>
        );
    }
}


class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            description: '',
            profileImage: null,
            panelToModify: null,
            text: 'Upload',
            isCoverModified: false,
            isProfileModified: false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {

        if (this.props.location.state != undefined) {
            this.FillFields();
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        this.setState({
            [inputName]: inputValue
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.ChannelSubmit();
    }

    OnImageUpload(e) {
        var file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            document.getElementById('gameUploadImg').setAttribute('src', reader.result);
            document.getElementById('gameUploadImg').style.display = 'block';

            document.getElementsByClassName("cover-div")[0].classList.remove("cover-div-border");

        }
    }


    configureAlert(heading, message, type) {

        const alertPlaceholder = document.getElementById('liveAlertPlaceholder')

        const alert = (heading, message, type) => {
            const wrapper = document.createElement('div')
            wrapper.innerHTML = [

                `<div class="alert dark alert-${type} alert-dismissible" role="alert">`
                , ` <h4 class="alert-heading">${heading}</h4>`,
                `   <div>${message}</div>`,
                '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
                '</div>'
            ].join('')

            alertPlaceholder.childNodes.forEach(value => value.remove())
            alertPlaceholder.append(wrapper)
        }

        const alertTrigger = document.getElementById('liveAlertBtn')
        if (alertTrigger) {
            alertTrigger.addEventListener('click', () => {
                alert(heading, message, type)
            })
        }
        alert(heading, message, type)
    }



    ChannelSubmit() {


        // this.configureAlert('Verifying Contents', '', 'success');

        // let request = new XMLHttpRequest();

        // request.open("POST", API_BASE_URL + "/panelApi/create", true)

        // request.onreadystatechange = function () {

        //     if (this.status === 201) {

        //         this.configureAlert('Channel Created', 'channel is created successfully! You can now upload games.', 'success');
        //         // <Redirect to={"/"} />

        //     }

        //     else if (this.status >= 400) {

        //         this.configureAlert(this.status.toString(), 'An Error occurred. Please try again.', 'danger')
        //     }

        // }


        // request.onerror = function (ev) {
        //     console.log(ev)
        // }

        // request.withCredentials = true
        // request.setRequestHeader("Access-Control-Allow-Origin", "*")
        // request.setRequestHeader("Authorization", 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))

        let formData = new FormData();


        formData.append("name", document.getElementById("name").value)
        formData.append("panelDescription", document.getElementById("description").value)

        // console.log(formData.get("description"));
        // request.send(formData)

        if (this.state.text != "Update") {
            console.log("Creating");
            formData.append("coverImage", document.getElementById("coverImage").files[0])
            formData.append("profileImage", document.getElementById("profileImage").files[0])
            console.log(document.getElementById("coverImage").files[0]);
            console.log(document.getElementById("profileImage").files[0]);


            PanelAPI.createPanel(formData)
                .then(response => {
                    // console.log(response);
                    // if (response.status == 200)
                    Alert.success("Panel Created Successfully!");
                    this.props.history.push("/mypanel");
                })
                .catch(error => {
                    console.log("Aya Tha");
                    console.error(error);
                    Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
        }

        else {
            console.log("Updating");
            if (this.state.isCoverModified) {
                formData.append("coverImage", document.getElementById("coverImage").files[0])
            }

            if (this.state.isProfileModified) {
                formData.append("profileImage", document.getElementById("profileImage").files[0])
            }


            PanelAPI.updatePanel(formData)
                .then(response => {
                    // console.log(response);
                    // if (response.status == 200)
                    this.props.history.push("/mypanel");
                    Alert.success("Panel Updated Successfully!");
                })
                .catch(error => {
                    console.log("Aya Tha");
                    console.error(error);
                    Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
        }

    }


    OnProfileUpload(e) {
        var file = e.target.files[0];
        let reader = new FileReader();
        var box = document.getElementById('profileBox');
        box.innerHTML = "";

        reader.readAsDataURL(file);
        reader.onload = () => {
            var myImg = document.createElement('img');
            myImg.src = reader.result;

            myImg.classList.add("mb-3");
            myImg.classList.add("rounded-3");


            box.appendChild(myImg);

            this.UpdateProfileSeparately();



            // document.getElementById('gameUploadImg').setAttribute('src', reader.result);
            // document.getElementById('gameUploadImg').style.display = 'block';
            // document.getElementsByClassName("cover-div")[0].classList.remove("cover-div-border");

        }
    }



    OnCoverUpload(e) {
        var file = e.target.files[0];
        let reader = new FileReader();
        var box = document.getElementById('coverBox');
        box.innerHTML = "";

        reader.readAsDataURL(file);
        reader.onload = () => {
            var myImg = document.createElement('img');
            myImg.src = reader.result;

            myImg.classList.add("mb-3");
            myImg.classList.add("rounded-3");


            box.appendChild(myImg);

            if (this.props.location.state != undefined)
                this.UpdateCoverSeparately();

            // document.getElementById('gameUploadImg').setAttribute('src', reader.result);
            // document.getElementById('gameUploadImg').style.display = 'block';
            // document.getElementsByClassName("cover-div")[0].classList.remove("cover-div-border");

        }
    }


    ExistingProfile() {
        // var file = e.target.files[0];
        // let reader = new FileReader();
        var box = document.getElementById('profileBox');
        box.innerHTML = "";

        // reader.readAsDataURL(file);
        // reader.onload = () => {
        var myImg = document.createElement('img');
        myImg.src = this.props.location.state.panelToModify.profileImageUrl;

        myImg.classList.add("mb-3");
        myImg.classList.add("rounded-3");


        box.appendChild(myImg);

        // document.getElementById('gameUploadImg').setAttribute('src', reader.result);
        // document.getElementById('gameUploadImg').style.display = 'block';
        // document.getElementsByClassName("cover-div")[0].classList.remove("cover-div-border");

        // }
    }



    ExistingCover() {
        // var file = e.target.files[0];
        // let reader = new FileReader();
        var box = document.getElementById('coverBox');
        box.innerHTML = "";

        // reader.readAsDataURL(file);
        // reader.onload = () => {
        var myImg = document.createElement('img');
        myImg.src = this.props.location.state.panelToModify.coverImageUrl;

        myImg.classList.add("mb-3");
        myImg.classList.add("rounded-3");


        box.appendChild(myImg);

    }



    UpdateCoverSeparately() {

        this.setState({
            isCoverModified: true
        })

        console.log("Updated Cover");

        // let formData = new FormData();


        // formData.append("name", document.getElementById("name").value)

        // formData.append("panelDescription", document.getElementById("description").value)

        // formData.append("coverImage", document.getElementById("coverImage").files[0])

        // // formData.append("profileImage", document.getElementById("profileImage").files[0])


        // PanelAPI.updatePanel(formData)
        //     .then(response => {
        //         console.log(response);
        //         if (response.status == 200)
        //             Alert.success("Cover Updated Successfully!");
        //     })
        //     .catch(error => {
        //         console.log("Aya Tha");
        //         console.error(error);
        //         Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
        //     });


    }


    UpdateProfileSeparately() {

        this.setState({
            isProfileModified: true
        })

        console.log("Updated Profile");
        // let formData = new FormData();

        // formData.append("name", document.getElementById("name").value)
        // formData.append("panelDescription", document.getElementById("description").value)
        // // formData.append("coverImage", document.getElementById("coverImage").files[0])
        // formData.append("profileImage", document.getElementById("profileImage").files[0])

        // PanelAPI.updatePanel(formData)
        //     .then(response => {
        //         console.log(response);
        //         if (response.status == 200)
        //             Alert.success("Cover Updated Successfully!");
        //     })
        //     .catch(error => {
        //         console.log("Aya Tha");
        //         console.error(error);
        //         Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
        //     });


    }


    FillFields() {
        this.setState(
            {
                name: this.props.location.state.panelToModify.name,
                description: this.props.location.state.panelToModify.panelDescription,
                text: "Update"
            }
        );

        this.ExistingCover();
        this.ExistingProfile();
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit} >
                <div id="liveAlertPlaceholder"></div>

                {/* Name */}
                <div className="text-start form-group text-white mb-4">
                    <label data-testid="name" htmlFor='name'>Panel Name</label>
                    <input required type="text" onChange={this.handleInputChange} value={this.state.name} name="name" className="form-control bg-dark text-white" id="name" placeholder="Enter Your Panel Name" />
                </div>

                {/* Slogan */}
                <div className="text-start form-group text-white mb-4">
                    <label data-testid="name" htmlFor='description'>Bio</label>
                    <input required type="text" onChange={this.handleInputChange} value={this.state.description} name="description" className="form-control bg-dark text-white" id="description" placeholder="Enter Your Bio" />
                </div>

                <div className='w-100 p-4 rounded-4 mt-2 primary-bg'>
                    <div id='coverBox'>

                    </div>

                    <label htmlFor='coverImage' className='w-100 text-center btn'> {this.state.text} Cover Image
                        <input accept='image/*' onChange={event => this.OnCoverUpload(event)} name='coverImage' id='coverImage' type="file" size="60" />
                    </label>
                </div>


                <div className='w-100 p-4 rounded-4 mt-2 primary-bg'>
                    <div id='profileBox'>
                        <div className='w-100 ratio-16x9 mb-3 p-2 rounded-3 placeholderImage' >
                            <div className="text-center">
                                Recommended (300x300)
                            </div>
                        </div>
                    </div>

                    <label htmlFor='profileImage' className='w-100 text-center btn'> {this.state.text} Profile Image
                        <input accept='image/*' onChange={event => this.OnProfileUpload(event)} name='profileImage' id='profileImage' type="file" size="60" />
                    </label>
                </div>

                <div className="form-item">
                    <button type="submit" className="btn btn-block red-background text-white mt-3" >{this.state.text} My Panel</button>
                </div>
            </form>

        );
    }
}

export default CreatePanel
