import React, { Component } from 'react';
import './Signup.css';
import { Link, Redirect } from 'react-router-dom'
import { GOOGLE_AUTH_URL, FACEBOOK_AUTH_URL, GITHUB_AUTH_URL } from '../../../API/Constants';
import { signup } from '../../../API/Configs/APIUtils';
import Alert from 'react-s-alert';


import PasswordChecklist from "react-password-checklist"

class Signup extends Component {
    render() {
        if (this.props.authenticated) {
            return <Redirect
                to={{
                    pathname: "/",
                    state: { from: this.props.location }
                }} />;
        }

        return (
            <div className="s-signup-container appheader-margin slide-in-bottom">
                <div className="s-signup-content">
                    <h1 className="s-signup-title">Signup to <span style={{ color: "#E13536" }}>Playtube</span></h1>

                    <SignupForm {...this.props} />
                    <SocialSignup />
                    <div className="s-login-link">Already have an account? <Link to="/login">Login!</Link></div>
                </div>
            </div>
        );
    }
}


class SocialSignup extends Component {
    render() {
        return (

            <div class="mt-3">
                <label className='text-white mb-4'>Or Signup with</label>
                <ul className='zaroori'>
                    <li><a href={FACEBOOK_AUTH_URL}>
                        <div className='facebook-btn'>
                            <i class="zmdi zmdi-facebook zmdi-hc-3x"></i>
                        </div>

                    </a></li>
                    <li><a href={GITHUB_AUTH_URL}> <div className='github-btn'>
                        <i class="zmdi zmdi-github zmdi-hc-3x"></i>
                    </div></a></li>
                    <li><a href={GOOGLE_AUTH_URL}><div className='google-btn'>
                        <i class="zmdi zmdi-google zmdi-hc-3x"></i>
                    </div></a></li>
                </ul>
            </div>
        );
    }
}

class SignupForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            password: '',
            isValid: false,
            // againPassword: '',
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        this.setState({
            [inputName]: inputValue
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.isValid) {


            const signUpRequest = Object.assign({}, this.state);

            signup(signUpRequest)
                .then(response => {
                    Alert.success("You're successfully registered. Please login to continue!");
                    this.props.history.push("/login");
                }).catch(error => {
                    Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
        }
        else {
            Alert.error('Invalid Password or Email. Please try again!');
        }

    }

    render() {
        return (

            <form onSubmit={this.handleSubmit}>
                <>

                    {/* Name */}
                    <div className="text-start form-group text-white mb-4">
                        <label data-testid="name" htmlFor='name'>Name</label>
                        <input required type="text" onChange={this.handleInputChange} value={this.state.name} name="name" className="form-control bg-dark text-white" id="name" placeholder="Enter Your Name" />
                    </div>

                    {/* Email */}
                    <div className="text-start form-group text-white mb-4">
                        <label data-testid="name" htmlFor='email'>Email</label>
                        <input required type="email" onChange={this.handleInputChange} value={this.state.email} name="email" className="form-control bg-dark text-white" id="email" placeholder="Enter Your Email" />
                    </div>



                    {/* Password */}
                    <div className="text-start form-group text-white mb-4">
                        <label data-testid="password" htmlFor='password'>Password</label>
                        <input required type="password" onChange={this.handleInputChange} value={this.state.password} name="password" className="form-control bg-dark text-white" id="password" placeholder="Enter Your Password" />
                    </div>


                    {/* Again Password */}
                    {/* <div className="text-start form-group text-white mb-4">
                    <label data-testid="againPassword" htmlFor='againPassword'>Again Password</label>
                    <input required type="password" onChange={this.handleInputChange} value={this.state.againPassword} name="againPassword" className="form-control bg-dark text-white" id="againPassword" placeholder="Enter Your Password Again" />
                </div> */}


                    <div className='mb-3'>
                        <PasswordChecklist
                            style={{ color: 'whitesmoke' }}
                            rules={["minLength", "specialChar", "number"]}
                            minLength={8}
                            value={this.state.password}
                            // valueAgain={this.state.againPassword}
                            onChange={(isValid) => {
                                this.state.isValid = isValid;
                            }}
                        />
                    </div>




                    {/* <div className="form-item">
                        <button onClick={this.handleSubmit} type="submit" className="btn btn-block red-background text-white " >Sign Up</button>
                    </div> */}

                    <div className="form-item">
                        <button type="submit" className="btn btn-block red-background text-white " >Sign Up</button>
                    </div>




                </>
            </form>





        );
    }
}

export default Signup