import { API_BASE_URL } from "../../Constants"
import { api, apiHeader } from "../../Configs/axiosConfig"
import { defineCancelApiObject } from "../../Configs/axiosUtils"

export const GameTagRecommendationAPI = {
    recommendGameTags: async function (count, cancel = false) {
        const response = await api.request({
            url: "/gameTagRecommend/" + count,
            method: "GET",

            withCredentials: true,
            headers: apiHeader,

            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response.data;
    },





}

// defining the cancel API object for GameTagRecommendationAPI
const cancelApiObject = defineCancelApiObject(GameTagRecommendationAPI)