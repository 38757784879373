import { axiosMultipart } from "../../../Configs/axiosConfig"
import { defineCancelApiObject } from "../../../Configs/axiosUtils"


export const GameScreenshotsAPI = {
    updateScreenshots: async function (formData, cancel = false) {
        const response = await axiosMultipart.request({
            url: "/screenshot/update",
            method: "POST",
            data: formData,
            withCredentials: true,


            _signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response;
    },

}

// defining the cancel API object for GameAPI
const cancelApiObject = defineCancelApiObject(GameScreenshotsAPI)