
import React, { useState, useEffect } from 'react';
import { GameAPI } from '../../API/Endpoints/Backend/GameAPI';
import { SubscriptionAPI } from '../../API/Endpoints/Backend/SubscriptionAPI';
import FullWidthTabs from './Components/Tab';

import "./Panel.css"
import GamesGrid from '../Home/Components/GamesGrid';
import { PanelAPI } from '../../API/Endpoints/Backend/PanelAPI';

const PublicPanel = (props) => {

    const queryParams = new URLSearchParams(window.location.search);
    const panelId = queryParams.get('id');

    //Variables
    // const [panelGames, setpanelGames] = useState([]);
    const [myPanel, setmyPanel] = useState(null);


    const subscribe = () => {
        if (props.authenticated) {
            console.log("Subscribed")

            SubscriptionAPI.subscribe(panelId);
            document.getElementById('subText').innerHTML = "<div>Unsubscribe</div>"
            document.getElementById('subText').onclick = unSubscribe;
        }
    }

    const unSubscribe = () => {
        if (props.authenticated) {
            console.log("UnSubscribed")

            SubscriptionAPI.unSubscribe(panelId);
            document.getElementById('subText').innerHTML = "<div>Subscribe</div>"
            document.getElementById('subText').onclick = subscribe;
        }
    }

    const subscribeExist = () => {
        if (props.authenticated) {
            SubscriptionAPI.exist(panelId).then((response) => {
                if (response.data) {
                    document.getElementById('subText').innerHTML = "<div>Unsubscribe</div>"
                    document.getElementById('subText').onclick = unSubscribe;
                }
                else {
                    document.getElementById('subText').innerHTML = "<div>Subscribe</div>"
                    document.getElementById('subText').onclick = subscribe;
                }
            });
        }
    }
    const GetPanel = () => {
        // GameAPI.getByPanel(panelId, 0, 1)
        //     .then((Games) => {
        //         if (Games != null) {
        //             console.log(Games);
        //             setpanelGames(Games)
        //         }
        //     })


        PanelAPI.getPublicPanel(panelId)
            .then((json) => {
                setmyPanel(json);
                // setpanelId(myPanel.panelId);
                console.log(json);
            })
    }


    useEffect(() => {

        GetPanel();
        subscribeExist();
    }, [])

    return (

        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="page-content">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="main-profile">

                                    {
                                        (myPanel != null) ?
                                            <div style={{ backgroundImage: "url(" + myPanel.coverImageUrl + ")" }} className="main-banner mb-4 d-none d-lg-block">
                                            </div>
                                            : null
                                    }

                                    <div class="row">
                                        <div class="col-lg-4 align-self-center">

                                            {
                                                (myPanel != null) ?
                                                    <img src={myPanel.profileImageUrl} className='panel-profile-pic' />
                                                    : null
                                            }

                                        </div>

                                        <div class="col-lg-4 align-self-center text-lg-left text-center">
                                            <div class="main-info header-text">

                                                {
                                                    (myPanel != null) ? <div>

                                                        <h4>{myPanel.name}</h4>
                                                        {/* <i onClick={event => OnDeleteClick(myPanel)} className="fa fa-trash mx-2 text-danger"></i> */}
                                                        {/* <i onClick={event => OnEditClick(myPanel)} className="fa fa-edit text-primary"></i> */}

                                                        {/* onClick={event => this.OnDeleteClick(game)} */}

                                                        <p>{myPanel.panelDescription}</p>
                                                    </div> : null
                                                }

                                                <div id='subText' onClick={event => subscribe()} className="main-button mt-3 text-white">
                                                    <div>Subscribe</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4  align-self-center">

                                            {
                                                (myPanel != null) ?
                                                    <ul>
                                                        {/* <li>Total Games <span>{myPanel.length}</span></li> */}
                                                        <li>Total Likes<span>{myPanel.totalLikes}</span></li>
                                                        <li>Total Plays <span>{myPanel.totalPlays}</span></li>
                                                        <li>Total Playtime <span>None</span></li>
                                                    </ul>

                                                    : null
                                            }

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>


                        {/* <!-- ***** Banner End ***** --> */}

                        {/* <FullWidthTabs panelId={panelId} /> */}

                        {
                            (myPanel != null) ?
                                <GamesGrid
                                    gridType="recommendation"
                                    recommendationType="Panel"
                                    panelId={panelId}
                                    // genre="Action"
                                    {...props} />
                                // <div>
                                //     <h4><FullWidthTabs authorized={1} panelId={myPanel.panelId} /></h4>

                                // </div> 
                                : null
                        }




                    </div>
                </div>
            </div>
        </div >

    );

}
export default PublicPanel;