import React, { Component } from 'react';

import Banner from './Components/Banner';
import GameGrid from './Components/GamesGrid';
import './Home.css';

import TagTab from './Components/TagTab';


import { connect } from 'react-redux';
import { getActiveGameTag } from '../../Redux/selectors';
import { huntMyColor, result } from '../../Helper/ColorHuntData';
import ThemeModal from './Components/ColorPicker/ThemeModal';

class Home extends Component {

    state = {
        games: [],
        activeTag: "All",
        DataisLoaded: false,
        showTagGrid: false,
        temp: 0,
        colorPalette: null,
        showModal: false
    };

    constructor(props) {
        super(props);
        // this.handleClick.bind = this.handleClick;
        // this.handleCloseModal.bind = this.handleCloseModal;
    }

    showSelectedTag = (tag) => {

    }


    componentDidMount() {
        this.props.handleLogin();
    }


    // handleShowModal() {
    //     this.setState({
    //         showModal: true
    //     })

    //     // this.state.showModal = true;
    //     // setModalShow(true);
    //     // this.setState({ showModal: true });
    // };

    // handleCloseModal() {
    //     // this.state.showModal = false;
    //     this.setState({
    //         showModal: false
    //     })
    //     // setModalShow(false);
    //     // this.setState({ showModal: false });
    // };

    // handleSaveModal() {
    //     this.setState({
    //         showModal: false
    //     })
    //     // Add your save logic here
    //     // setModalShow(false);
    //     // this.setState({ showModal: false });
    // };




    render() {
        return (
            <>

                <div class="container slide-in-bottom ">
                    <div class="row ">
                        <div class="col-lg-12">
                            <div class="page-content shadow-bg">
                                <Banner />
                                <TagTab ontabClick={this.showSelectedTag} />

                                {
                                    (this.props.activeGameTag != "All") ?

                                        // <div id='tagDiv'>
                                        <GameGrid
                                            gridType="recommendation"
                                            recommendationType="Tag"
                                            tagName={this.props.activeGameTag}
                                            temp={this.state.temp}
                                            {...this.props} />

                                        // </div>
                                        : null
                                }
                                <GameGrid
                                    gridType="recommendation"
                                    recommendationType="Recommended"
                                    // genre="Action"
                                    {...this.props} />

                                <GameGrid
                                    gridType="recommendation"
                                    recommendationType="Popular"
                                    // genre="Action"
                                    {...this.props} />

                                <GameGrid
                                    gridType="recommendation"
                                    recommendationType="Subscribed"
                                    // genre="Action"
                                    {...this.props} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )

    }
}

function mapStateToProps(state) {
    return {
        activeGameTag: getActiveGameTag(state),
    };
}

export default connect(mapStateToProps)(Home);