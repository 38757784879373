import Modal from 'react-bootstrap/Modal';
// import { ModalRoute } from 'react-router-modal';
import React, { useEffect } from 'react';

import convert from 'htmr';
// import { Link } from 'react-router-dom';

import DefaultComponent from "./CommentSection";
import Image_Viewer from './Image-Viewer';

import "../../Game/gameview.css";
import { LikesAPI } from '../../../API/Endpoints/Backend/LikesAPI';
// import { React_Base_URL } from '../../../Constants';




// import { ModalContainer, ModalRoute } from 'react-router-modal';
// import { BrowserRouter, Link } from 'react-router-dom';

// assumes webpack loader for css
// ... or include this css however you do that in your project ...
// import 'react-router-modal/css/react-router-modal.css'

// function FooModal() {
//     return <div>FOO</div>;
// }

// function BarModal() {
//     return <div>BAR</div>;
// }

// function Example() {
//     return (
//         <BrowserRouter>
//             <div>
//                 <Link to='/foo'>show foo</Link>
//                 <Link to='/bar'>show bar</Link>

//                 <ModalRoute parentPath='/' path='/foo' >hello Worlds</ModalRoute>
//                 <ModalRoute component={BarModal} path='/bar' className='test-modal test-modal-bar' />

//                 <ModalContainer />
//             </div>
//         </BrowserRouter>
//     );
// }




function GameModal(props) {




    const makeFullScreen = (e) => {
        document.getElementsByTagName("iframe")[0].className = "fullScreen";
        var element = document.getElementsByTagName("iframe")[0];

        var requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullscreen;

        if (requestMethod) { // Native full screen.
            requestMethod.call(element);
        }
        window.screen.orientation.lock("landscape");
    }


    const like = () => {
        if (props.authenticated) {
            console.log("Liked")

            LikesAPI.like(props.game.gameId);

            document.getElementById('like').classList.remove("text-secondary")
            document.getElementById('like').onclick = unlike;
        }
    }

    const unlike = () => {
        if (props.authenticated) {
            console.log("UnLiked")

            LikesAPI.unlike(props.game.gameId);

            document.getElementById('like').classList.add("text-secondary")
            document.getElementById('like').onclick = like;
        }
    }


    const likeExist = () => {
        if (props.authenticated) {
            LikesAPI.exist(props.game.gameId).then((response) => {
                console.log(response);
                if (response.data) {

                    document.getElementById('like').classList.remove("text-secondary")
                    document.getElementById('like').onclick = unlike;


                }
                else {

                    document.getElementById('like').classList.add("text-secondary")
                    document.getElementById('like').onclick = like;

                }
            });
        }
        else {
            document.getElementById('like').classList.add("text-secondary")
            document.getElementById('like').onclick = like;
        }

    }


    useEffect(() => {

        likeExist();


    })


    return (
        <>


            <Modal

                contentClassName='game-modal rounded-5'
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Body style={{ marginBottom: "100px" }} className='rounded-5 p-0'>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="page-content">

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="feature-banner header-text">
                                                <div class="row d-flex text-center">

                                                    <div className='videoWrapper'>

                                                        <iframe
                                                            className='rounded-5'
                                                            frameborder="0"
                                                            allowfullscreen="true" scrolling="no"
                                                            allow="autoplay; fullscreen *; geolocation; microphone; camera; midi; monetization; xr-spatial-tracking; gamepad; gyroscope; accelerometer; xr; cross-origin-isolated"
                                                            allowtransparency="true" webkitallowfullscreen="true" mozallowfullscreen="true"
                                                            msallowfullscreen="true"
                                                            src={props.game.source}
                                                            // src="https://v6p9d9t4.ssl.hwcdn.net/html/2726184/index.html"
                                                            id="game_drop">

                                                        </iframe>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    {/* <!-- ***** Details Start ***** --> */}
                                    <div class="game-details mt-4">
                                        <div class="row">

                                            <div class="col-lg-12">
                                                <div class="content">
                                                    <div class="row">
                                                        <div class="col-lg-6">

                                                            <div class="left-info">
                                                                <div className='row'>
                                                                    <div className='col'>
                                                                        <div class="text-center">
                                                                            <h4>{props.game.gameTitle}</h4>
                                                                            <span>{props.game.genreName}</span>

                                                                        </div>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <i onClick={event => makeFullScreen(event)} class="fas fa-2x fa-expand text-white cursor-pointer"></i>
                                                                    </div>

                                                                </div>



                                                            </div>





                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="right-info">
                                                                <ul>
                                                                    <li><i id='like' class="fa fa-star"></i> 4.8</li>
                                                                    <li><i id='play' class="fa fa-play"></i> 2.3M</li>
                                                                    <li><i id='play' class="fa fa-share"></i> Share</li>
                                                                    {/* <li><i class="fa fa-server"></i> 36GB</li>
                                                                <li><i class="fa fa-gamepad"></i> Action</li> */}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='row secondary-bg rounded-4 p-3'>
                                                        <h3 className='mt-3 mb-1 red-font'><u>Description</u></h3>
                                                        {convert(decodeURI(props.game.gameDescription))}

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div class="game-details text-white mt-4">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="content">
                                                    <div class="row">
                                                        <h3 className='text-center mb-3'>Screenshots</h3>
                                                        {
                                                            (props.game.gamescreenshots != null) ?
                                                                <Image_Viewer images={props.game.gamescreenshots} />
                                                                : null
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div class="game-details text-white mt-4">
                                        <div class="row">

                                            <div class="col-lg-12">
                                                <div class="content">
                                                    <div class="row">
                                                        <DefaultComponent />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    );
}
export default GameModal;