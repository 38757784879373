import React, { useState, useCallback, useEffect } from "react";
import { createRoot } from "react-dom/client";
import ImageViewer from "react-simple-image-viewer";

export default function Image_Viewer(props) {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    // const images = [
    //     props.images[0].screenShot,
    //     props.images[1].screenShot,
    //     props.images[2].screenShot
    // ];

    const [images, setImages] = useState([]);


    useEffect(() => {
        props.images.forEach(element => {
            setImages(oldArray => [...oldArray, element.screenShot]);
            // console.log(element.screenShot);
            // images.push(element.screenShot);
        });

    }, [])


    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    return (
        <div className="row">
            {images.map((src, index) => (
                <div className="col-lg">
                    <img
                        src={src}
                        onClick={() => openImageViewer(index)}
                        // width="300"
                        key={index}
                        className="rounded-3 img-zoom"

                    />
                </div>

            ))}

            {isViewerOpen && (
                <ImageViewer
                    src={images}
                    currentIndex={currentImage}
                    onClose={closeImageViewer}
                    disableScroll={true}
                    backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.9)"
                    }}
                    closeOnClickOutside={true}
                />
            )}
        </div>
    );
}
