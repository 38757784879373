import React, { useEffect, useState } from 'react';
import { API_BASE_URL, React_Base_URL } from '../../API/Constants';

import { NavLink } from 'react-router-dom';

import InfiniteLoader from 'react-infinite-loader';

import HoverVideoPlayer from 'react-hover-video-player';

import GameModal from '../Home/Components/GameModal';
// import GamesGrid from '../Home/Components/GamesGrid'; '../Home/Components/GamesGrid';
import GamesGrid from '../Home/Components/GamesGrid';



// import GamesGrid from '../Home/Component/GamesGrid';

const Browse = (props) => {

    // const params = new URLSearchParams(window.location.pathname);
    const queryParams = new URLSearchParams(window.location.search);

    const genre = queryParams.get('genre');
    const search = queryParams.get('search');


    // const [searchParams] = useSearchParams;
    const [content, setContent] = useState([]);
    const [browsedGames, setBrowsedGames] = useState([]);

    const [pageNo, setPageNo] = useState(0);

    const [modalShow, setModalShow] = useState(false);
    const [modalGame, setModalGame] = useState(null);


    function OnGameClick(modalShow, modalGame) {
        setModalShow(modalShow);
        setModalGame(modalGame);
        console.log(modalGame);

    }



    const fetchGamesByGenre = () => {
        let url = API_BASE_URL + "/game/page/" + genre + "/" + "0" + "/" + "20";
        // let url = API_BASE_URL + "/game/gameList";

        fetch(
            url)
            .then((res) => res.json())
            .then((json) => {
                if (json.content != null) {
                    let games = [];
                    games = games.concat(browsedGames, json.content);

                    setBrowsedGames(games);
                    console.log(browsedGames)
                    setPageNo(pageNo + 1);
                }
            })
    }

    const fetchGamesBySearch = () => {
        let url = API_BASE_URL + "/game/search/" + search;
        // console.log(url)
        // let url = API_BASE_URL + "/game/gameList";

        fetch(
            url)
            .then((res) => res.json())
            .then((json) => {
                if (json != null) {
                    let games = [];
                    games = games.concat(browsedGames, json);

                    setBrowsedGames(games);
                    console.log(browsedGames)
                    setPageNo(pageNo + 1);
                }
            })
    }


    // const fetchGenreGames = () => {
    //     setContent(fetchGamesByGenre(genre));

    //     if (content != null) {
    //         let games = [];
    //         games = games.concat(browsedGames, content);

    //         setBrowsedGames(games);
    //         // console.log(browsedGames)
    //         setPageNo(pageNo + 1);
    //     }
    // }

    useEffect(() => {
        // console.log("Hello", props.authenticated);
        // fetchGenreGames();
        // if (search != null) {
        //     fetchGamesBySearch();
        // } else if (genre != null) {
        //     fetchGamesByGenre();
        // }
        // console.log(search);

    }, [])



    function loadItems() {
        // fetchGenreGames();
        if (search != null) {
            fetchGamesBySearch();
        } else if (genre != null) {
            fetchGamesByGenre();
        }
    }

    function handleVisit() {
        loadItems()
    }

    function getItems() {
        let items = []
        for (var i = 0; i < 10; i++) {
            items.push({ name: 'An item' })
        }
        return items
    }

    function renderCards() {
        const { items } = this.state
        const cards = items.map((item, i) => {
            return (
                <div key={i}><h3>{item.name}</h3></div>
            )
        })
        return cards
    }

    return (

        <>
            {/* {
                (modalGame != null) ?
                    <GameModal
                        game={modalGame}
                        show={modalShow}
                        onHide={() => setModalShow(false)}

                    /> : null
            } */}

            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="page-content shadow-bg">

                            <GamesGrid
                                gridType="search"
                                searchQuery={search}
                                // recommendationType="Recommended"
                                // genre="Action"
                                {...props} />


                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
export default Browse;


// const 





// <div className="most-popular">
//                                 {
//                                     (genre != null) ?
//                                         <div className="heading-section">
//                                             <h4><em> <a href={React_Base_URL + "/browse?genre=" + genre}>{genre}</a></em> Games</h4>
//                                         </div>
//                                         : null
//                                 }

//                                 <div className="row">
//                                     <div className="col-lg-12">

//                                         <div className="row">

//                                             {
//                                                 browsedGames != [] ?
//                                                     browsedGames.map(game => (

//                                                         <div key={game.gameId} className="col-lg-3 col-sm-6">
//                                                             <div className="item">

//                                                                 {/* <a href={React_Base_URL + "/gaming?id=" + game.gameId}>  */}

//                                                                 <NavLink to={
//                                                                     {
//                                                                         pathname: "/game/" + game.gameId
//                                                                     }} >
//                                                                     <HoverVideoPlayer
//                                                                         videoClassName='video-player'
//                                                                         onClick={() => OnGameClick(true, game)}
//                                                                         videoSrc={game.video}
//                                                                         pausedOverlay={
//                                                                             <img
//                                                                                 src={game.cover}
//                                                                                 // src="thumbnail-image.jpg"
//                                                                                 alt=""
//                                                                                 style={{
//                                                                                     // Make the image expand to cover the video's dimensions
//                                                                                     width: '100%',
//                                                                                     height: '100%',
//                                                                                     objectFit: 'cover',
//                                                                                 }}
//                                                                             />
//                                                                         }
//                                                                         loadingOverlay={
//                                                                             <div className="loading-overlay">
//                                                                                 <div className="loading-spinner" />
//                                                                             </div>
//                                                                         }
//                                                                     />
//                                                                 </NavLink>
//                                                                 {/* <img onClick={() => OnGameClick(true, game)} src={game.cover} alt="" /> */}
//                                                                 {/* </a> */}
//                                                                 <h4>{game.gameTitle}<br />
//                                                                     <a href={React_Base_URL + "/panel?id=" + game.panelId}>

//                                                                         <span className='panel-span'> <i className="fa-solid fa-house"></i>{game.name}</span>

//                                                                     </a><hr />

//                                                                     <span className='genre'>{game.genreName}</span></h4>

//                                                                 <ul>
//                                                                     <li><i className="fa fa-star"></i> 4.8</li>
//                                                                     <li><i className="fa fa-play"></i> 2.3M</li>
//                                                                 </ul>

//                                                                 {
//                                                                     (props.authorized) ? <h4><hr /></h4> : null

//                                                                 }

//                                                                 {
//                                                                     (props.authorized) ?
//                                                                         <div className='text-center'>
//                                                                             <NavLink to={React_Base_URL + "/upload-game?id=" + game.gameId} className='btn btn-primary btn-block px-4'>Edit</NavLink>
//                                                                             <button onClick={deleteBtnClick(game.gameId)} className='btn btn-danger btn-block px-3 ms-2'>Delete</button>
//                                                                         </div> : null
//                                                                 }





//                                                             </div>
//                                                         </div>

//                                                     )) : null
//                                             }
//                                             <InfiniteLoader onVisited={() => handleVisit()} />

//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>





const deleteBtnClick = (gameId) => {

    // console.log(gameId);

    // axios.delete(API_BASE_URL + "/game/delete", {
    //   params: {
    //     "gameId": gameId
    //   },
    //   headers: {
    //     "Authorization": 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
    //     "Access-Control-Allow-Origin": "*"
    //   }
    // });
    // .then(() => window.location.reload(false));

    // games.

    // fetch(
    //   API_BASE_URL + "/game/delete/" + gameId, {
    //   method: 'DELETE',
    //   headers: {
    //     "Authorization": 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
    //     "Access-Control-Allow-Origin": "*"
    //   }
    // })
    //   .then(() => { });
}