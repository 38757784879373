// import { ShareSocial } from 'react-share-social'
import { useState } from "react";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineIcon,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterIcon,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    WorkplaceShareButton
} from "react-share";
import GameShareModal from "../Game/Components/GameShare";

const Testing = (props) => {

    // const separator = useState("\n")
    var description = "";
    var link = "";
    const [title, setTitle] = useState("Playube Game \n Hello World \n")
    const [modalShow, setModalShow] = useState(false);
    const [modalGameLink, setModalGameLink] = useState(null);

    return (
        <>
            {
                // (modalGameLink != null) ?
                <GameShareModal
                    gameLink={modalGameLink}
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    {...props}
                />


            }



        </>

    );
}
export default Testing;