import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import HoverVideoPlayer from 'react-hover-video-player';

import { connect } from 'react-redux';
import { getActiveGameTag } from '../../../Redux/selectors';

import { React_Base_URL } from '../../../API/Constants';

import { GameRecommendationAPI } from '../../../API/Endpoints/Recombee/GameRecommendationAPI';
import { PaginationAPI } from '../../../API/Endpoints/Recombee/PaginationAPI';
import { GameSearchRecommendationAPI } from '../../../API/Endpoints/Recombee/GameSearchRecommendationAPI';
import { PanelAPI } from '../../../API/Endpoints/Backend/PanelAPI';
import { ExampleModal } from './ExampleModal';

import { AbbreviateNumber } from '../../../Helper/Helper';


class GamesGrid extends Component {

  constructor(props) {
    super(props);

    this.state = {
      maxGames: 20,
      totalGames: [],
      games: [],
      gameIndex: 0,
      recommendationId: 0,
      gamesHeader: "",
      gameToModify: null,
      showModal: false,

    };

    this.OnDeleteClick = this.OnDeleteClick.bind(this);
  }


  componentDidMount() {
    if (this.props.gridType == "recommendation") {
      // console.log("recommendation");
      this.GetRecommendationResults();
    }
    else if (this.props.gridType == "search") {
      this.GetSearchResults();
    }
    else if (this.props.gridType == "tag") {
      this.GetTagResults();
    }
    else if (this.props.gridType == "rds") {
      this.GetRDSResults();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeGameTag !== this.props.activeGameTag) {
      if (this.props.gridType == "recommendation")
        if (this.props.recommendationType == "Tag")
          this.onNewTagClick();
    }
  }

  GetTagResults() {
    if (this.props.authenticated) {
      GameRecommendationAPI.recommendGamesOnHomePage(this.state.maxGames)
        .then((res) => {
          this.setState(
            {
              gamesHeader: "'" + this.props.tagName + "' Tagged",
              recommendationId: res.recommendId,
              games: res.recommendedItems,
              totalGames: res.recommendedItems
            }
          )
        });

    }
  }

  GetSearchResults() {
    // if (this.props.authenticated) {
    GameSearchRecommendationAPI.searchGamesByPersonalization(this.props.searchQuery, this.state.maxGames)
      .then((res) => {
        this.setState(
          {
            gamesHeader: '"' + this.props.searchQuery + '" SearchTerm',
            recommendationId: res.recommendId,
            games: res.recommendedItems,
            totalGames: res.recommendedItems
          }
        )
      });
    // }
  }


  GetRDSResults() {
    if (this.props.authenticated) {
      //MyPanel Games
      switch (this.props.rdsType) {
        case "PanelGames":
          PanelAPI.findAllPanelGames()
            .then((res) => {
              console.log(res);
              // console.log(this.props.currentUser)
              this.setState(
                {
                  gamesHeader: "My",
                  recommendationId: -1,
                  games: res,
                  totalGames: res
                }
              )
            });
          break;
      }

    }
    else {

    }
  }



  GetRecommendationResults() {
    // if (this.props.authenticated) {
    if (this.props.gridType == "recommendation") {
      switch (this.props.recommendationType) {

        case "Recommended":
          GameRecommendationAPI.recommendGamesOnHomePage(this.state.maxGames)
            .then((res) => {
              this.setState(
                {
                  gamesHeader: this.props.recommendationType,
                  recommendationId: res.recommendId,
                  games: res.recommendedItems,
                  totalGames: res.recommendedItems
                }
              )
            });
          break;

        case "Popular":
          GameRecommendationAPI.recommendPopularGamesInMonth(this.state.maxGames)
            .then((res) => {
              this.setState(
                {
                  gamesHeader: this.props.recommendationType,
                  recommendationId: res.recommendId,
                  games: res.recommendedItems,
                  totalGames: res.recommendedItems
                }
              )
            });
          break;

        case "Subscribed":
          GameRecommendationAPI.recommendSubscribedGames(this.state.maxGames)
            .then((res) => {
              this.setState(
                {
                  gamesHeader: this.props.recommendationType,
                  recommendationId: res.recommendId,
                  games: res.recommendedItems,
                  totalGames: res.recommendedItems
                }
              )
            });
          break;

        case "Similar":
          GameRecommendationAPI.recommendSimilarGames(this.props.recommendGameId, this.state.maxGames)
            .then((res) => {
              this.setState(
                {
                  gamesHeader: this.props.recommendationType,
                  recommendationId: res.recommendId,
                  games: res.recommendedItems,
                  totalGames: res.recommendedItems
                }
              )
            });
          break;

        case "Panel":
          console.log("recommendation");
          GameRecommendationAPI.recommendGamesByPanel(this.props.panelId, this.state.maxGames)
            .then((res) => {
              this.setState(
                {
                  gamesHeader: this.props.recommendationType,
                  recommendationId: res.recommendId,
                  games: res.recommendedItems,
                  totalGames: res.recommendedItems
                }
              )
            });
          break;
        case "Tag":
          console.log("recommendation");


          GameRecommendationAPI.recommendGamesByTag(this.props.activeGameTag, this.state.maxGames)
            .then((res) => {
              this.setState(
                {
                  gamesHeader: this.props.activeGameTag,
                  recommendationId: res.recommendId,
                  games: res.recommendedItems,
                  totalGames: res.recommendedItems
                }
              )
            });
          break;

        default:
          break;
      }


      // }
    }
  }




  onPrevClick() {

    this.state.gameIndex = this.state.gameIndex - this.state.maxGames;

    this.setState(
      {
        games: (this.state.totalGames.slice(this.state.gameIndex, this.state.gameIndex + this.state.maxGames)),
      }
    )
  }

  onNextClick() {
    if (!((this.state.gameIndex + this.state.maxGames) >= this.state.totalGames.length)) {
      console.log("without recommendation");

      this.state.gameIndex = this.state.gameIndex + this.state.maxGames;
      this.setState(
        {
          games: (this.state.totalGames.slice(this.state.gameIndex, this.state.gameIndex + this.state.maxGames - 1)),
          // gameIndex: (this.state.gameIndex + this.state.maxGames)
        }
      )
    }
    else {
      console.log("with recommendation");
      PaginationAPI.recommendNextGames(this.state.recommendationId, this.state.maxGames)
        .then((res) => {
          this.setState(
            {
              recommendationId: res.recommendId,
              totalGames: [...this.state.totalGames, ...res.recommendedItems],
              games: res.recommendedItems,
              gameIndex: (this.state.gameIndex + this.state.maxGames)
            }
          )
        });
    }
  };

  onNewTagClick() {
    GameRecommendationAPI.recommendGamesByTag(this.props.activeGameTag, this.state.maxGames)
      .then((res) => {
        this.setState(
          {
            gamesHeader: this.props.activeGameTag,
            recommendationId: res.recommendId,
            games: res.recommendedItems,
            totalGames: res.recommendedItems
          }
        )
      });
  }


  OnDeleteClick(game) {
    this.setState({
      gameToModify: game,
      modelShow: true,

    })

    this.handleShowModal();

  }



  handleShowModal = () => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  handleSaveModal = () => {
    // Add your save logic here
    this.setState({ showModal: false });
  };



  OnEditClick(game) {
    // this.setState({
    //   gameToModify: game,
    // })


    this.props.history.push({
      pathname: '/upload-game',
      state: { gameToModify: game, isModify: true },
    });

    // Redirect to the new route with props
    // history.push('/upload-game', { gameToModify: game });
  }




  render() {

    return (

      <>
        {
          (this.state.gameToModify != null) ?
            <>

              <ExampleModal
                show={this.state.showModal}
                handleClose={this.handleCloseModal}
                handleSave={this.handleSaveModal}
                game={this.state.gameToModify}
              />
            </>
            : null
        }

        <div className="most-popular slide-in-bottom-item shadow-bg">
          <div className="row">
            <div className="col-lg-12">
              <div className="heading-section">
                {
                  (this.props.panelId != null) ?
                    <div className='row'>
                      <div className='col-lg-6'>
                        {
                          (this.state.games != [] && this.state.games != undefined) ?
                            // <h4><em>{this.state.games[0].name}</em> Games</h4>
                            <h4>Games</h4>
                            : null
                        }
                      </div>

                    </div> :

                    <div className='row '>
                      <div className='col-lg-10'>
                        <h4><em> <a href="#">{this.state.gamesHeader}</a></em> Games</h4>
                      </div>


                      {
                        (this.state.recommendationId != -1) ?
                          <div className='col pagination-button'>

                            {/* Previous button icon */}
                            {this.state.gameIndex > 0 && (
                              <button className='px-3 py-1 mb-3 rounded-3' onClick={() => this.onPrevClick()}>

                                {'<<'}
                              </button>
                            )}

                            {/* Next button icon */}
                            <button className='px-3 py-1 mb-3 rounded-3 ms-3' onClick={() => this.onNextClick()}>
                              {'>>'}
                            </button>
                          </div>
                          : null
                      }

                    </div>
                }

              </div>

              <div className="row">

                {

                  (this.state.games != [] && this.state.games != undefined) ?
                    this.state.games.map(game => (
                      <div key={game.gameId} className="col-lg-3 col-sm-6 fade-in">
                        <div className="item shadow-bg">

                          <NavLink to={
                            {
                              pathname: "/game/" + game.gameId,
                              state: { recommendationId: this.state.recommendationId || null }
                            }} >

                            <HoverVideoPlayer
                              videoClassName='video-player'
                              onClick={() => this.OnGameClick(true, game)}
                              videoSrc={(game.video != undefined) ? game.video : ""}
                              pausedOverlay={
                                <img
                                  src={game.cover}
                                  // src="thumbnail-image.jpg"
                                  alt=""
                                  style={{
                                    // Make the image expand to cover the video's dimensions
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                  }}
                                />
                              }
                              loadingOverlay={
                                <div className="loading-overlay">
                                  <div className="loading-spinner" />
                                </div>
                              }
                            />
                          </NavLink>


                          {/* Game Title */}
                          <h4>
                            {/* {game.gameTitle} */}
                            {
                              (!game.gameEnabled) ?
                                <div className='text-danger mb-2'> This game is disabled and not publicly visible</div>
                                : null
                            }

                            <div className='text-truncate supporting-fg'>{game.gameTitle}</div>
                            {/* <br /> */}

                            {/* Panel Title */}
                            <a href={React_Base_URL + "/publicpanel?id=" + game.panelId}>
                              <span className='panel-span hover-me'> <i className="fa-solid fa-house"></i>{game.name}</span>
                            </a>

                            {/* Break Section */}
                            <hr />


                            {/* Game Genre */}
                            <span className='genre'>{game.genreName}</span>
                          </h4>


                          <ul>
                            {/* Game Ratings => Stars & Plays */}
                            <li className='float-start'><i className="fa fa-star"></i> {AbbreviateNumber(game.gameTotalLikes)}</li>
                            <li className='float-start'><i className="fa fa-play"></i> {AbbreviateNumber(game.gameTotalPlays)}</li>

                            {/* Game Delete & Edit => Only Dev */}
                            {
                              (this.props.isDev) ?
                                <>
                                  <li className='float-end'><i onClick={event => this.OnDeleteClick(game)} className="fa fa-trash mx-2 text-danger"></i></li>
                                  <li className='float-end'><i onClick={event => this.OnEditClick(game)} className="fa fa-edit text-primary"></i></li>
                                </> : null
                            }

                          </ul>
                          {/* {
                            (this.props.authorized) ?
                              <div className='text-center'>
                                <NavLink to={React_Base_URL + "/upload-game?id=" + game.gameId} className='btn btn-primary btn-block px-4'>Edit</NavLink>
                                <button onClick={deleteBtnClick(game.gameId)} className='btn btn-danger btn-block px-3 ms-2'>Delete</button>
                              </div> : null
                          } */}

                        </div>
                      </div>
                    )) : null
                }
              </div>
            </div>
          </div>
        </div >
      </>
    )
  }



}
function mapStateToProps(state) {
  return {
    activeGameTag: getActiveGameTag(state),
  };
}

export default connect(mapStateToProps)(GamesGrid);





// const GamesGrid = (props) => {

//State Variables
// const maxGames = 8;
// const [totalGames, setTotalGames] = useState([]);
// const [games, setGames] = useState([]);
// const [gameIndex, setGameIndex] = useState(0);

// var totalGames = [];
// var games = [];
// var gameIndex = 0;

// const prevGamesList = games.slice(gameIndex - maxGames, gameIndex);
// const nextGamesList = games.slice(gameIndex, gameIndex + maxGames);

// const [recommendationId, setRecommendationId] = useState("");
// const [gamesHeader, setGamesHeader] = useState("");

// //Pagination
// const [prev, setPrev] = useState(0);
// const [next, setNext] = useState(10);
// const [page, setPage] = useState(10);


// const [modalShow, setModalShow] = useState(false);
// const [modalGame, setModalGame] = useState(null);


// useEffect(() => {
//   GetRecommendations();
//   // console.log(gameIndex);
//   // console.log
// }, [])



// const GetRecommendations = () => {
//   if (props.authenticated) {
//     if (props.recommendation) {
//       // setGameIndex(gameIndex + maxGames)
//       switch (props.recommendationType) {

//         case "Recommended":
//           setGamesHeader(props.recommendationType);
//           GameRecommendationAPI.recommendGamesOnHomePage(maxGames)
//             .then((res) => {
//               // console.log(res);
//               setRecommendationId(res.recommendId);
//               setGames(res.recommendedItems);
//               setTotalGames(res.recommendedItems);

//             });
//           break;
//         case "Popular":
//           setGamesHeader(props.recommendationType);
//           GameRecommendationAPI.recommendPopularGamesInMonth(maxGames)
//             .then((res) => {
//               // console.log(res);
//               setRecommendationId(res.recommendId);
//               setGames(res.recommendedItems);
//               setTotalGames(res.recommendedItems);
//             });
//           break;
//         case "Subscribed":
//           setGamesHeader(props.recommendationType);
//           GameRecommendationAPI.recommendSubscribedGames(maxGames)
//             .then((res) => {
//               // console.log(res);
//               setRecommendationId(res.recommendId);
//               setGames(res.recommendedItems);
//               setTotalGames(res.recommendedItems);
//             });
//           break;
//         case "Similar":
//           setGamesHeader(props.recommendationType);
//           GameRecommendationAPI.recommendSimilarGames(props.recommendGameId, maxGames)
//             .then((res) => {
//               // console.log(res);
//               setRecommendationId(res.recommendId);
//               setGames(res.recommendedItems);
//               setTotalGames(res.recommendedItems);
//             });
//           break;

//         default:
//           break;
//       }


//     }
//   }




//Hooks
// useEffect(() => {


// else if (props.panelId) {
//   GameAPI.getByPanel(props.panelId, 0, 20)
//     .then((Games) => {
//       if (Games != null) {
//         console.log(Games);
//         setGames(Games)
//       }

//     })
// }

// }

// if (props.tag) {
//   GameAPI.getAll()
//     .then((Games) => {
//       if (Games != null) {
//         console.log(Games);
//         setGames(Games)
//       }
//     })
// }

// else {
//   GameAPI.getByGenre(props.genre, 0, 8)
//     .then((Games) => {
//       console.log(Games);
//       setGames(Games)
//     })
// }
// }

// }, [])




// const onPrevClick = useCallback(() => {

//   if (gameIndex > 0)
//     setGameIndex(gameIndex - maxGames)
//   else return;

//   console.log(gameIndex);

//   console.log(totalGames.slice(gameIndex, gameIndex + maxGames))
//   console.log(totalGames);
//   setGames(totalGames.slice(gameIndex, gameIndex + maxGames - 1));
//   // setGameIndex(gameIndex + maxGames)


// }, []);



// const onNextClick = () => {
//   if (!((gameIndex + maxGames) >= totalGames.length)) {
//     setGames(totalGames.slice(gameIndex), gameIndex + maxGames - 1);
//     setGameIndex(gameIndex + maxGames)
//   }
//   else {
//     PaginationAPI.recommendNextGames(recommendationId, maxGames)
//       .then((res) => {

//         // console.log(res);
//         setRecommendationId(res.recommendId);

//         setTotalGames(totalGames => [...totalGames, ...res.recommendedItems]);

//         setGames(res.recommendedItems);

//         setGameIndex(gameIndex => gameIndex + maxGames)
//         // setGames(res.recommendedItems);
//         console.log(totalGames);

//       });
//   }
//   console.log(gameIndex);
// }



//Event Calls
// function OnGameClick(modalShow, modalGame) {
//   // setModalShow(modalShow);
//   setModalGame(modalGame);

//   // console.log(modalGame);
// }


//   return (
//     <>

//       {
//         (modalGame != null) ?
//           <GameModal
//             game={modalGame}
//             show={modalShow}
//             onHide={() => setModalShow(false)}

//             {...props}

//           /> : null
//       }

//       <div className="most-popular slide-in-bottom-item shadow-bg">
//         <div className="row">
//           <div className="col-lg-12">
//             <div className="heading-section">
//               {
//                 (props.panelId != null) ?
//                   <div className='row '>
//                     <div className='col-lg-6'>
//                       {
//                         (games != [] && games != undefined) ?
//                           <h4><em>{games[0].name}</em> Games</h4>
//                           : null
//                       }
//                     </div>

//                   </div> :

//                   <div className='row '>

//                     {/* <div className='col-lg-6'>
//                     <h4><em> <a href={React_Base_URL + "/browser?genre=" + props.genre}>{props.genre}</a></em> Games</h4>
//                   </div> */}

//                     <div className='col-lg-10'>
//                       <h4><em> <a href="#">{gamesHeader}</a></em> Games</h4>
//                     </div>

//                     <div className='col pagination-button'>

//                       {/* Previous button icon */}
//                       {gameIndex > 0 && (
//                         <button className='px-3 py-1 mb-3 rounded-3' onClick={() => onPrevClick()}>
//                           {/* {'<<'} {maxGames} Games */}
//                           {/* &#8249;&#8249; */}
//                           {'<<'}
//                         </button>
//                       )}

//                       {/* Next button icon */}
//                       <button className='px-3 py-1 mb-3 rounded-3 ms-3' onClick={() => onNextClick()}>
//                         {/* {maxGames} Games {'>>'} */}
//                         {/* &#8250; */}
//                         {'>>'}
//                       </button>



//                       {/* <i onClick={onPrevClick()} className="fa fa-solid fa-arrow-left fa-beat fa-xl text-white-50"></i>
//                     <span className='text-white-50'> {prev}-{next} </span>
//                     <i onClick={onNextClick()} className="fa fa-solid fa-arrow-right fa-beat fa-xl text-white-50"></i> */}

//                       {/* <GridPagination /> */}

//                       {/* <h4>
//                       <Link to={{
//                         pathname: "/preloaded",
//                         state: { genre: props.genre }
//                       }}> <em>Pre</em></Link> Load
//                     </h4> */}
//                     </div>
//                   </div>
//               }

//             </div>

//             <div className="row">
//               {/* <div className="col-lg-3 col-sm-6">

//               <InFeedAd />
//             </div> */}
//               {

//                 (games != [] && games != undefined) ?
//                   games.map(game => (
//                     <div className="col-lg-3 col-sm-6 fade-in">
//                       <div className="item shadow-bg">

//                         <NavLink to={
//                           {
//                             pathname: "/game/" + game.gameId
//                           }} >

//                           <HoverVideoPlayer
//                             videoClassName='video-player'
//                             onClick={() => OnGameClick(true, game)}
//                             videoSrc={game.video}
//                             pausedOverlay={
//                               <img
//                                 src={game.cover}
//                                 // src="thumbnail-image.jpg"
//                                 alt=""
//                                 style={{
//                                   // Make the image expand to cover the video's dimensions
//                                   width: '100%',
//                                   height: '100%',
//                                   objectFit: 'cover',
//                                 }}
//                               />
//                             }
//                             loadingOverlay={
//                               <div className="loading-overlay">
//                                 <div className="loading-spinner" />
//                               </div>
//                             }
//                           />
//                         </NavLink>


//                         <h4>{game.gameTitle}<br />
//                           <a href={React_Base_URL + "/publicpanel?id=" + game.panelId}>

//                             <span className='panel-span'> <i className="fa-solid fa-house"></i>{game.name}</span>

//                           </a><hr />

//                           <span className='genre'>{game.genreName}</span></h4>

//                         <ul>
//                           <li><i className="fa fa-star"></i> {game.gameTotalLikes}</li>
//                           <li><i className="fa fa-play"></i> {game.gameTotalPlays}</li>
//                         </ul>

//                         {
//                           (props.authorized) ? <h4><hr /></h4> : null

//                         }

//                         {
//                           (props.authorized) ?
//                             <div className='text-center'>
//                               <NavLink to={React_Base_URL + "/upload-game?id=" + game.gameId} className='btn btn-primary btn-block px-4'>Edit</NavLink>
//                               <button onClick={deleteBtnClick(game.gameId)} className='btn btn-danger btn-block px-3 ms-2'>Delete</button>
//                             </div> : null
//                         }

//                       </div>
//                     </div>
//                   )) : null
//               }
//             </div>
//           </div>
//         </div>
//       </div >
//     </>
//   )
// }

// export default GamesGrid;





const deleteBtnClick = (gameId) => {

  // console.log(gameId);

  // axios.delete(API_BASE_URL + "/game/delete", {
  //   params: {
  //     "gameId": gameId
  //   },
  //   headers: {
  //     "Authorization": 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
  //     "Access-Control-Allow-Origin": "*"
  //   }
  // });
  // .then(() => window.location.reload(false));

  // games.

  // fetch(
  //   API_BASE_URL + "/game/delete/" + gameId, {
  //   method: 'DELETE',
  //   headers: {
  //     "Authorization": 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
  //     "Access-Control-Allow-Origin": "*"
  //   }
  // })
  //   .then(() => { });
}





// //API Calls
  // const fetchGamesByGenre = () => {
  //   // let url = API_BASE_URL + "/game/page/" + props.genre + "/" + "0" + "/" + "8";

  //   GameAPI.getByGenre(props.genre, 0, 8)
  //     .then((Games) => {
  //       console.log(Games);
  //       setGames(Games)
  //     })

  //   // fetch(
  //   //   url)
  //   //   .then((res) => res.json())
  //   //   .then((json) => {
  //   //     setGames(json);
  //   //   })
  // }

  // const fetchGamesByPanelId = () => {
  //   // let url = API_BASE_URL + "/game/panelPage/" + props.panelId + "/" + "null" + "/" + "0" + "/" + "20";

  //   GameAPI.getByPanel(props.panelId, 0, 8)
  //     .then((Games) => {
  //       console.log(Games);
  //       setGames(Games)
  //     })
  // }

