import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import Alert from 'react-s-alert';
import { GameAPI } from "../../../API/Endpoints/Backend/GameAPI";
import { ACCESS_TOKEN, API_BASE_URL, React_Base_URL } from "../../../API/Constants";
import { PanelAPI } from "../../../API/Endpoints/Backend/PanelAPI";



function AlertBox({ message, color, isVisible }) {
    const displayStyle = isVisible ? {} : { display: 'none' };

    return (
        <div className="text-center" style={{ ...displayStyle, backgroundColor: color, padding: '6px', borderRadius: '7px', color: 'white', border: 'solid 2px white' }}>
            {message}
        </div>
    );
}

AlertBox.defaultProps = {
    isVisible: false,
};

export class PanelDeleteModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: '',
            showAlert: false,
            message: "",
            color: ""
        };
    }
    handleInputChange = (event) => {

        this.setState({ inputValue: event.target.value });
    }

    DeleteGame() {
        this.setState({ showAlert: true });

        if (this.state.inputValue.length == 0) {

            this.setState({
                message: "Panel Name field is required",
                color: "red"
            });
        }
        else if (this.state.inputValue != this.props.panel.name) {
            this.setState({
                message: "Panel Name you entered is incorrect",
                color: "red"
            });
            // Alert.error('Game Title you entered is incorrect');
        }
        else {
            this.setState({
                message: "Deleting In Progress",
                color: "yellow"
            });

            PanelAPI.deletePanel()
                .then((res) => {
                    Alert.success("Panel Deleted!");
                    this.props.history.push('/');
                });
        }

    }
    render() {
        return (
            <Modal
                contentClassName='game-modal rounded-5'
                aria-labelledby="contained-modal-title-vcenter"

                centered
                show={this.props.show}
                onHide={this.props.handleClose}
            >

                <Modal.Body className='rounded-3 p-5'>

                    <div className='row'>
                        <div className='col-12'>
                            <img className='video-player' src={this.props.panel.profileImageUrl} alt="" srcset="" />
                            <div className="text-white text-center mt-1 "><b>{this.props.panel.name}</b></div>
                        </div>
                        <hr className='mt-2' style={{ color: "whitesmoke" }} />

                        <div className="text-danger text-center "><b>Warning</b></div>
                        <div className='col text-center text-white'>
                            Deleting the uploaded build will make it no longer available for download and may inconvenience users. Check for active users and keep backups as a precaution. Consider all options before making a final decision. Thank you for using our platform.
                        </div>

                    </div>

                    <hr style={{ color: "whitesmoke" }} />


                    <div className="form-group text-white mb-4 copy-clipboard-button">

                        <input required
                            type="text"
                            autoFocus
                            className="form-control bg-dark text-white w-100 mt-2"
                            id="gameDeleteTitle"
                            placeholder="Enter your panel name to delete"

                            value={this.state.inputValue}
                            onChange={this.handleInputChange}

                        />

                        <button onClick={event => this.DeleteGame()} className="btn form-control mt-3" >Delete</button>

                    </div>
                    <AlertBox message={this.state.message} color={this.state.color} isVisible={this.state.showAlert} />

                </Modal.Body>

            </Modal>
        );
    }
}