import { API_BASE_URL, ACCESS_TOKEN } from "../../Constants"
import { api, apiHeader } from "../../Configs/axiosConfig"
import { defineCancelApiObject } from "../../Configs/axiosUtils"

export const SubscriptionAPI = {
    subscribe: async function (id, cancel = false) {
        const response = await api.request({
            url: "/subscription/subscribe",
            method: "POST",
            params: {
                panelId: id,
            },

            headers: apiHeader,
            // apiHeader,
            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        console.log(response);
    },

    unSubscribe: async function (id, cancel = false) {
        const response = await api.request({
            url: "/subscription/unsubscribe",
            method: "POST",
            params: {
                panelId: id,
            },

            headers: apiHeader,
            // apiHeader,
            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        console.log(response);
    },

    exist: async function (id, cancel = false) {
        const response = await api.request({
            url: "/subscription/exist",
            method: "POST",
            params: {
                panelId: id,
            },

            headers: apiHeader,

            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response;
    },
}

// defining the cancel API object for GameAPI
const cancelApiObject = defineCancelApiObject(SubscriptionAPI)