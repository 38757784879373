export const AbbreviateNumber = (value) => {
    if (value == 0) {
        return "0";
    }
    var newValue = value;
    if (value >= 1000) {
        var suffixes = ["", "k", "m", "b", "t"];
        var suffixNum = Math.floor(("" + value).length / 3);
        var shortValue = '';
        for (var precision = 2; precision >= 1; precision--) {
            shortValue = parseFloat((suffixNum != 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision));
            var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
            if (dotLessShortValue.length <= 2) { break; }
        }
        if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
        newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
}



export const getMobileOS = () => {
    const ua = navigator.userAgent
    if (/android/i.test(ua)) {
        return "Android"
    }
    else if ((/iPad|iPhone|iPod/.test(ua)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
        return "iOS"
    }
    return "Other"
}

export const checkDeviceOS = () => {
    var OSName = "Unknown OS";
    var userAgent = navigator.userAgent;

    if (userAgent.indexOf("Win") != -1) {
        OSName = "Windows";
    } else if (userAgent.indexOf("Mac") != -1) {
        OSName = "MacOS";
    } else if (userAgent.indexOf("X11") != -1) {
        OSName = "UNIX";
    } else if (userAgent.indexOf("Linux") != -1) {
        OSName = "Linux";
    } else if (userAgent.indexOf("Android") != -1) {
        OSName = "Android";
    } else if (userAgent.indexOf("iPhone") != -1 || userAgent.indexOf("iPad") != -1) {
        OSName = "iOS";
    }

    console.log("The user's OS is " + OSName);

    return OSName;

}
