import React, { Component } from 'react';

import { GOOGLE_AUTH_URL, FACEBOOK_AUTH_URL, GITHUB_AUTH_URL, ACCESS_TOKEN, React_Base_URL } from '../../../API/Constants';
import { login } from '../../../API/Configs/APIUtils';
import { Link, Redirect } from 'react-router-dom'
import Alert from 'react-s-alert';

import './Login.css';

class Login extends Component {
    componentDidMount() {
        // If the OAuth2 login encounters an error, the user is redirected to the /login page with an error.
        // Here we display the error and then remove the error query parameter from the location.
        if (this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                Alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
    }

    render() {
        if (this.props.authenticated) {
            return <Redirect
                to={{
                    pathname: React_Base_URL,
                    state: { from: this.props.location }
                }} />;
        }

        return (
            <div className="login-container appheader-margin slide-in-bottom">
                <div className="login-content login-card">
                    <h1 className="login-title">Login to <span style={{ color: "#E13536" }}>Playtube</span></h1>
                    <LoginForm {...this.props} />
                    <SocialLogin />

                    <div className="signup-link">New user? <Link to="/signup">Sign up!</Link></div>
                </div>
            </div>
        );
    }
}

class SocialLogin extends Component {
    render() {
        return (
            <div class="mt-4">
                <label className='text-white mb-4'>Or login with</label>
                <ul className='zaroori'>
                    <li>
                        <a href={FACEBOOK_AUTH_URL}>
                            <div className='facebook-btn'>
                                <i class="zmdi zmdi-facebook zmdi-hc-3x"></i>
                            </div>

                        </a>
                    </li>
                    <li><a href={GITHUB_AUTH_URL}> <div className='github-btn'>
                        <i class="zmdi zmdi-github zmdi-hc-3x"></i>
                    </div></a></li>
                    <li><a href={GOOGLE_AUTH_URL}><div className='google-btn'>
                        <i class="zmdi zmdi-google zmdi-hc-3x"></i>
                    </div></a></li>
                </ul>
            </div>
        );
    }
}


class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        this.setState({
            [inputName]: inputValue
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        const loginRequest = Object.assign({}, this.state);

        login(loginRequest)
            .then(response => {
                console.log(response);
                localStorage.setItem(ACCESS_TOKEN, response.accessToken);
                // this.props.authenticated = true;
                this.props.onLogin();
                console.log(this.props.authenticated);
                Alert.success("You're successfully logged in!");
                // <Redirect
                //     to={{
                //         pathname: React_Base_URL,
                //         state: { from: this.props.location }
                //     }} />;

                // const navigate = useNavigate();

                // navigate(React_Base_URL);
                this.props.history.push("/");
            }).catch(error => {
                Alert.error((error && error.message) || 'Oops! Maybe Invalid Email or Password :(');
            });
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>

                {/* Name
                <div className="text-start form-group text-white mb-4">
                    <label data-testid="name" htmlFor='name'>Name</label>
                    <input required type="text" onChange={this.handleInputChange} value={this.state.name} name="name" className="form-control bg-dark text-white" id="name" placeholder="Enter Your Name" />
                </div> */}

                {/* Email */}
                <div className="text-start form-group text-white mb-4">
                    <label data-testid="name" htmlFor='email'>Email</label>
                    <input required type="text" onChange={this.handleInputChange} value={this.state.email} name="email" className="form-control bg-dark text-white" id="email" placeholder="Enter Your Email" />
                </div>



                {/* Password */}
                <div className="text-start form-group text-white mb-4">
                    <label data-testid="password" htmlFor='password'>Password</label>
                    <input required type="password" onChange={this.handleInputChange} value={this.state.password} name="password" className="form-control bg-dark text-white" id="password" placeholder="Enter Your Password" />
                </div>

                {/* <div className="form-item">
                    <input type="text" name="name"
                        className="form-control" placeholder="Name"
                        value={this.state.name} onChange={this.handleInputChange} required />
                </div>
                <div className="form-item">
                    <input type="email" name="email"
                        className="form-control" placeholder="Email"
                        value={this.state.email} onChange={this.handleInputChange} required />
                </div>
                <div className="form-item">
                    <input type="password" name="password"
                        className="form-control" placeholder="Password"
                        value={this.state.password} onChange={this.handleInputChange} required />
                </div> */}
                <div className="form-item">
                    <button type="submit" className="btn btn-block red-background text-white " >Login</button>
                </div>
            </form>


            // <form onSubmit={this.handleSubmit}>
            //     <div className="form-item">
            //         <input type="email" name="email"
            //             className="form-control" placeholder="Email"
            //             value={this.state.email} onChange={this.handleInputChange} required />
            //     </div>
            //     <div className="form-item">
            //         <input type="password" name="password"
            //             className="form-control" placeholder="Password"
            //             value={this.state.password} onChange={this.handleInputChange} required />
            //     </div>
            //     <div className="form-item">
            //         <button type="submit" className="btn btn-block btn-primary">Login</button>
            //     </div>
            // </form>
        );
    }
}

export default Login
