import { API_BASE_URL } from "../../Constants"
import { api, apiHeader } from "../../Configs/axiosConfig"
import { defineCancelApiObject } from "../../Configs/axiosUtils"

export const GameRecommendationAPI = {
    recommendGamesOnHomePage: async function (itemCount, cancel = false) {
        const response = await api.request({
            url: "/gameRecommendation/homepage",
            method: "POST",

            params: {
                itemCount: itemCount,
            },

            withCredentials: true,
            // headers: apiHeader,

            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response.data;
    },

    recommendPopularGamesInMonth: async function (count, cancel = false) {
        const response = await api.request({
            url: "/gameRecommendation/popular",
            method: "POST",

            params: {
                count: count,
            },
            withCredentials: true,

            // headers: apiHeader,

            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response.data;
    },

    recommendSubscribedGames: async function (count, cancel = false) {
        const response = await api.request({
            url: "/gameRecommendation/subscribed",
            method: "POST",

            params: {
                count: count,
            },
            withCredentials: true,

            // headers: apiHeader,

            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response.data;
    },
    recommendSimilarGames: async function (gameId, count, cancel = false) {
        const response = await api.request({
            url: "/gameRecommendation/similar",
            method: "POST",

            params: {
                gameId: gameId,
                count: count,

            },
            withCredentials: true,

            headers: apiHeader,

            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response.data;
    },

    recommendGamesByPanel: async function (panelId, count, cancel = false) {
        const response = await api.request({
            url: "/gameRecommendation/panel",
            method: "POST",

            params: {
                panelId: panelId,
                count: count,

            },
            withCredentials: true,

            headers: apiHeader,

            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response.data;
    },

    recommendGamesByTag: async function (tagName, count, cancel = false) {
        const response = await api.request({
            url: "/gameRecommendation/tag",
            method: "POST",

            params: {
                tagName: tagName,
                count: count,

            },
            withCredentials: true,

            headers: apiHeader,

            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response.data;
    },



}

// defining the cancel API object for GameRecommendationAPI
const cancelApiObject = defineCancelApiObject(GameRecommendationAPI)