import React, { useState } from 'react';
import { render } from 'react-dom';
import { countryMap } from '../countries';
import './style.css';
import { WithContext as ReactTags } from 'react-tag-input';

const suggestions = countryMap.map(country => {
    return {
        id: country[0],
        text: country[1]
    };
});

const KeyCodes = {
    comma: 188,
    enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export const TagInput = () => {
    const [tags, setTags] = React.useState([]);

    const handleDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = tag => {
        setTags([...tags, tag]);
    };

    const handleDrag = (tag, currPos, newPos) => {
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        setTags(newTags);
    };

    const handleTagClick = index => {
        console.log('The tag at index ' + index + ' was clicked');
    };

    return (
        <ReactTags
            inline={false}
            classNames={
                "suggestions"
            }
            tags={tags}
            suggestions={suggestions}
            delimiters={delimiters}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            handleDrag={handleDrag}
            handleTagClick={handleTagClick}
            inputFieldPosition="bottom"
            autocomplete
        />
    );
};