// import { ShareSocial } from 'react-share-social'
import Modal from 'react-bootstrap/Modal';

import { useEffect, useState } from "react";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineIcon,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterIcon,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    WorkplaceShareButton
} from "react-share";
import { React_Base_URL } from '../../../API/Constants';

export default function GameShareModal(props) {
    const [title, setTitle] = useState('');
    const genre = useState(props.game.genreName);
    const creater = useState(props.game.name);
    const cover = useState(props.game.cover);
    const [url, setUrl] = useState('');
    // console.log(props.game);



    const CopyToClipboard = (event) => {

        // Get the text field
        var copyText = document.getElementById("gameLink");
        var copySpan = document.getElementById("cT");

        // Select the text field
        copyText.select();
        copyText.setSelectionRange(0, 99999); // For mobile devices

        // Copy the text inside the text field
        navigator.clipboard.writeText(copyText.value);
        console.log(copyText.value);

        copySpan.innerText = "Copied"
    }

    function SetupTtle() {
        setTitle(
            "Game Title: " + props.game.gameTitle + "\n" +
            "Genre: " + props.game.genreName + "\n" +
            "Creater: " + props.game.name + "\n" +
            "Link:"
        );
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        SetupTtle();
        setUrl(React_Base_URL + "/game/" + props.game.gameId);


    }, []);




    // const [title, setTitle] = useState("Playube Game \n Hello World \n")
    return (
        <Modal

            contentClassName='game-modal rounded-5'
            {...props}
            // size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Body className='rounded-3 p-5'>

                <div className='row'>
                    <div className='col-md-12 col-lg-7'>
                        <img className='video-player' src={props.game.cover} alt="" srcset="" />

                    </div>
                    <hr className='mt-2 d-lg-none' style={{ color: "whitesmoke" }} />

                    <div className='col text-sm-center'>
                        <span className='text-white'>Title: {props.game.gameTitle}</span><br />
                        <span className='text-white'>Genre: {props.game.genreName}</span><br />
                        <span className='text-white'>Panel: {props.game.name}</span><br />
                    </div>

                </div>

                <hr style={{ color: "whitesmoke" }} />

                <WhatsappShareButton
                    title={title}
                    url={url}
                    className="p-2 img-zoom"
                >
                    <WhatsappIcon className='img-zoom' size={64} round />
                </WhatsappShareButton>

                <LineShareButton
                    title="Playtube Game"
                    url="https://playtube.cloud"
                    className="p-2"

                >
                    <LineIcon className='img-zoom' size={64} round />
                </LineShareButton>


                <FacebookShareButton
                    quote=''
                    url="https://playtube.cloud"
                    className="p-2"

                >
                    <FacebookIcon className='img-zoom' size={64} round />
                </FacebookShareButton>

                <TwitterShareButton
                    title="Playtube Game"
                    url="https://playtube.cloud"
                    className="p-2"
                >
                    <TwitterIcon className='img-zoom' size={64} round />
                </TwitterShareButton>


                <EmailShareButton
                    className="p-2"
                >
                    <EmailIcon className='img-zoom' size={64} round />
                </EmailShareButton>

                <div className="form-group text-white mb-4 copy-clipboard-button">

                    <input required
                        type="text"
                        autoFocus
                        readOnly
                        disabled
                        className="form-control bg-dark text-white w-100 mt-2"
                        id="gameLink"
                        placeholder="Enter your game title"
                        value={url}
                    />

                    <div onClick={event => CopyToClipboard(event)} className='copy-clipboard-button' id='cT'>Copy</div>

                </div>

            </Modal.Body>
        </Modal >

    );



}