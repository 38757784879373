import React, { Component } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import convert from 'htmr';


class MyRichTextEditor extends Component {



  constructor(props) {
    super(props);
    const html = props.editorText;
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState,
      };
    }
  }

  onEditorStateChange = (editorState) => {
    this.props.onTextChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    this.setState({
      editorState,
    });
  };

  render() {
    const { editorState } = this.state;
    return (
      <>

        <div className='rounded-3'>
          <Editor
            editorState={editorState}
            wrapperClassName="rich-wrapper"
            editorClassName="rich-editor"
            toolbarClassName='rich-toolbar'
            onEditorStateChange={this.onEditorStateChange}
          />
        </div>
        <h3 className='mt-3'></h3>
        {/* <div className='text-white'>{convert(draftToHtml(convertToRaw(editorState.getCurrentContent())))}</div> */}
        <div className='mt-3'></div>
      </>
    );
  }
}

export default MyRichTextEditor;