import axios from "axios"
import { ACCESS_TOKEN, API_BASE_URL, RAWG_API_URL } from "../Constants";




export const api = axios.create({
    withCredentials: true,
    baseURL: API_BASE_URL,
})





export const apiRawg = axios.create({
    // withCredentials: true,
    baseURL: RAWG_API_URL,
})

export const axiosMultipart = axios.create({
    // withCredentials: true,
    baseURL: API_BASE_URL,
    headers: {
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'multipart/form-data',
        "Authorization": 'Bearer ' + localStorage.getItem(ACCESS_TOKEN)
    }
});



export const apiHeader = {
    "Access-Control-Allow-Origin": "*",
    "Authorization": 'Bearer ' + localStorage.getItem(ACCESS_TOKEN)

};

// defining a custom error handler for all APIs
const errorHandler = (error) => {
    const statusCode = error.response?.status

    // logging only errors that are not 401
    if (statusCode && statusCode !== 401) {
        console.error(error)
    }

    return Promise.reject(error)
}

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, (error) => {
    return errorHandler(error)
})