import { setThemeModal } from "./actions";

export const mapStateToProps = (state) => {
    return {
        themeModal: state.themeModal

    };
};


export const mapDispatchToProps = (dispatch) => {
    return {
        SetThemeModal: (themeModal) => dispatch(setThemeModal(themeModal)),
    };
};