import { axiosMultipart } from "../../../Configs/axiosConfig"
import { defineCancelApiObject } from "../../../Configs/axiosUtils"



export const GameCoverAPI = {
    updateCover: async function (formData, cancel = false) {
        const response = await axiosMultipart.request({
            url: "/gameCover/update",
            method: "POST",
            data: formData,
            withCredentials: true,


            _signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response;
    },

}

// defining the cancel API object for GameAPI
const cancelApiObject = defineCancelApiObject(GameCoverAPI)