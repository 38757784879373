import React, { Component } from 'react';

import "./Preloaded.css";
import "../Game/gameview.css"
import { API_BASE_URL } from "../../API/Constants";

class Preloaded extends Component {

    state = {
        games: [],
        genre: "",
        DataisLoaded: false,
        sectionCounter: 0,
        gamesCounter: 0
    };

    constructor(props) {
        super(props);
        this.setState({
            games: [],
            genre: "",
            DataisLoaded: false,
            sectionCounter: 0,
            gamesCounter: 0
        })


        this.state.genre = this.props.location.state.genre;
        this.fetchGamesByGenre();
    }

    btnFullScreen = (e, index) => {

        document.getElementsByTagName("iframe")[index].className = "fullScreen";
        var element = document.getElementsByTagName("iframe")[index];
        // requestFullScreen(elem);

        var requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullscreen;

        if (requestMethod) { // Native full screen.
            requestMethod.call(element);
        }
        window.screen.orientation.lock("landscape");
    }

    fetchGamesByGenre = () => {
        let url = API_BASE_URL + "/game/page/" + this.state.genre + "/" + "0" + "/" + "3";
        console.debug(url);
        fetch(
            url)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    games: json.content
                })
                this.nextSectionSelect();
            })
    }

    previousSectionSelect = () => {
        if (this.state.sectionCounter > 1) {
            this.state.sectionCounter--;
            document.getElementById("section_" + this.state.sectionCounter).scrollIntoView();
        }
    }

    nextSectionSelect = () => {
        if (this.state.sectionCounter < this.state.games.length) {
            document.getElementById("section_" + this.state.sectionCounter).scrollIntoView();
            this.state.sectionCounter++;

        } else {
            this.state.sectionCounter = 0;
            this.nextSectionSelect();
        }
    }

    gamesCounterInc() {
        return this.state.gamesCounter++;
    }




    render() {
        return (
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-sm-12">
                        <div class="page-content">
                            {
                                this.state.games != [] ?
                                    this.state.games.map((game, i) => (
                                        <section id={"section_" + this.gamesCounterInc()}>
                                            <div className="mb-5">
                                                <div className="arrow-icon-background m-auto" onClick={this.previousSectionSelect}>
                                                    <i class="arrow-icon fa-solid fa-arrow-up fa-2xl text-white"></i>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="feature-banner header-text">
                                                        <div class="row d-flex text-center">

                                                            <div className='videoWrapper'>
                                                                <iframe
                                                                    frameborder="0"
                                                                    allowfullscreen="true" scrolling="no"
                                                                    allow="autoplay; fullscreen *; geolocation; microphone; camera; midi; monetization; xr-spatial-tracking; gamepad; gyroscope; accelerometer; xr; cross-origin-isolated"
                                                                    allowtransparency="true" webkitallowfullscreen="true" mozallowfullscreen="true"
                                                                    msallowfullscreen="true"
                                                                    src={game.source}
                                                                    // src="https://v6p9d9t4.ssl.hwcdn.net/html/2726184/index.html"
                                                                    id="game_drop"></iframe>

                                                            </div>
                                                            <button onClick={event => this.btnFullScreen(event, i)}>Make Full Screen</button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="game-details">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="content detail-back-background">
                                                            <div class="row">
                                                                <div class="col-lg-6">
                                                                    <div class="left-info detail-background">
                                                                        <div class="text-center">
                                                                            <h4>{game.gameTitle}</h4>
                                                                            <span>Sandbox</span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <div class="right-info detail-background">
                                                                        <ul>
                                                                            <li><i class="fa fa-star"></i> 4.8</li>
                                                                            <li><i class="fa fa-download"></i> 2.3M</li>
                                                                            <li><i class="fa fa-server"></i> 36GB</li>
                                                                            <li><i class="fa fa-gamepad"></i> Action</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-5">
                                                <div className="arrow-icon-background m-auto" onClick={this.nextSectionSelect}>
                                                    <i class="arrow-icon fa-solid fa-arrow-down fa-2xl text-white"></i>
                                                </div>
                                            </div>
                                        </section>

                                    )) : console.log("Empty!")
                            }
                        </div>
                    </div>
                </div >
            </div >
        )
    }
}
export default Preloaded;
