import { API_BASE_URL, ACCESS_TOKEN, React_Base_URL } from '../Constants';

export const request = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    })

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }


    headers.append("Access-Control-Allow-Origin", React_Base_URL)

    const defaults = {
        headers: headers,

    };

    const credits = {
        credentials: "include",
    }
    options = Object.assign({}, defaults, options, credits);

    return fetch(options.url, options)
        .then(response =>
            response.json().then(json => {
                if (!response.ok) {
                    return Promise.reject(json);
                }
                return json;
            })
        );
};

export function getCurrentUser() {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }

    return request({

        url: API_BASE_URL + "/user/me",
        method: 'GET'
    });
}

export function login(loginRequest) {
    return request({
        url: API_BASE_URL + "/auth/login",
        method: 'POST',
        body: JSON.stringify(loginRequest),
        credentials: true,
    });
}

export function signup(signupRequest) {
    return request({
        url: API_BASE_URL + "/auth/signup",
        method: 'POST',
        body: JSON.stringify(signupRequest)
    });
}


// export function GetGamesByGenre(props) {
//     return request({
//         url: API_BASE_URL + "/game/page/" + props.genre + "/" + props.pageNo + "/" + props.size,
//         method: 'GET'
//     });
// }
