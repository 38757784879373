// src/redux/selectors.js

export function getActiveGameTag(state) {
    return state.activeGameTag;
}

export function getTagGames(state) {
    return state.tagGames;
}


export function getThemeModal(state) {
    return state.themeModal;
}