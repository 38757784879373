import React, { Component } from 'react';
import {
  Route,
  Switch
} from 'react-router-dom';

import AppHeader from '../Common/AppHeader';
import Home from '../Home/Home';
import UploadGame from '../Upload/UploadGame';
import Browse from '../Browse/Browse'
import GameView from '../Game/GameView';
import Preloaded from '../Preloaded/Preloaded';
import Login from '../Profile/login/Login';
import Signup from '../Profile/signup/Signup';
import Profile from '../Profile/profile/Profile';

import OAuth2RedirectHandler from '../Profile/oauth2/OAuth2RedirectHandler';
import NotFound from '../Common/NotFound';
import LoadingIndicator from '../Common/LoadingIndicator';
import { getCurrentUser } from '../../API/Configs/APIUtils';
import { ACCESS_TOKEN } from '../../API/Constants';
import PrivateRoute from '../Common/PrivateRoute';

import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import './App.css';

import PublicPanel from '../Profile--Panel/PublicPanel';
import MyPanel from '../Profile--Panel/MyPanel';
import CreatePanel from '../Profile--Panel/CreatePanel';
import Testing from '../test/Testing';
import NextPageView from '../Game/NextPageView';
import ScrollToTop from 'react-scroll-to-top';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBarTerm: "",
      authenticated: false,
      currentUser: null,
      loading: true
    }


    this.handleSearhTerm = this.handleSearhTerm.bind(this);
    this.loadCurrentlyLoggedInUser = this.loadCurrentlyLoggedInUser.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }


  handleSearhTerm = search => {
    this.setState({
      searchBarTerm: search
    })
    console.log(this.state.searchBarTerm);
  }

  loadCurrentlyLoggedInUser() {
    getCurrentUser()
      .then(response => {
        this.setState({
          currentUser: response,
          authenticated: true,
          loading: false
        });
      }).catch(error => {
        this.setState({
          loading: false
        });
      });
  }

  handleLogout() {

    localStorage.removeItem(ACCESS_TOKEN);
    this.setState({
      authenticated: false,
      currentUser: null
    });
    Alert.success("You're safely logged out!");

  }

  componentDidMount() {
    this.loadCurrentlyLoggedInUser();
    this.setupCustomTheme();
  }


  setupCustomTheme() {
    var customTheme = localStorage.getItem("CustomTheme");

    if (customTheme != undefined) {
      var data = JSON.parse(customTheme);

      // console.log(data);
      document.documentElement.style.setProperty('--red', `#${data.accent}`);
      document.documentElement.style.setProperty('--primary', `#${data.primary}`);
      document.documentElement.style.setProperty('--secondary', `#${data.secondary}`);
      document.documentElement.style.setProperty('--supporting', `#${data.suppporting}`);

    }
  }

  render() {
    if (this.state.loading) {
      return <LoadingIndicator />
    }

    return (
      <>
        <div className="app">
          <div className="app-top-box">
            <AppHeader searchTerm={this.state.searchBarTerm} onSearched={this.handleSearhTerm} authenticated={this.state.authenticated} currentUser={this.state.currentUser} onLogout={this.handleLogout} />
          </div>
          <div className="app-body">
            <ScrollToTop smotth color='white' style={{ backgroundColor: "var(--red)" }}></ScrollToTop>

            <Switch>
              <Route exact path="/" render={(props) => <Home handleLogin={this.loadCurrentlyLoggedInUser} authenticated={this.state.authenticated} />}></Route>

              <Route exact path="/test" component={Testing}></Route>


              {/* Game Routes */}
              <Route exact path="/upload-game" component={UploadGame}></Route>
              <Route exact path="/preloaded" component={Preloaded}></Route>
              <Route exact path="/game/:gameId" render={(props) => <GameView authenticated={this.state.authenticated} {...props} />}></Route>
              <Route exact path="/gameplay" component={NextPageView}></Route>




              <Route exact path="/browser" render={(props) => <Browse authenticated={this.state.authenticated} searchTerm={this.state.searchBarTerm} component={Browse} />}></Route>




              <Route exact path="/mypanel" render={(props) => <MyPanel authenticated={this.state.authenticated} currentUser={this.state.currentUser} {...props} />}></Route>
              <Route exact path="/publicpanel" render={(props) => <PublicPanel authenticated={this.state.authenticated} {...props} />}></Route>
              <Route exact path="/createpanel" component={CreatePanel}></Route>





              {/* User Credential Routes */}
              <PrivateRoute path="/profile" authenticated={this.state.authenticated} currentUser={this.state.currentUser}
                component={Profile}></PrivateRoute>
              <Route path="/login"
                render={(props) => <Login authenticated={this.state.authenticated} onLogin={this.loadCurrentlyLoggedInUser} {...props} />}></Route>
              <Route path="/signup"
                render={(props) => <Signup authenticated={this.state.authenticated} {...props} />}></Route>
              <Route path="/oauth2/redirect" component={OAuth2RedirectHandler}></Route>
              <Route component={NotFound}></Route>
              {/* <ModalContainer /> */}

            </Switch>
          </div>
          <Alert stack={{ limit: 3 }}
            timeout={3000}
            position='top-right' effect='slide' offset={65} />
        </div>

        {/* <Switch>
          <Route exact path="/gameplay" component={NextPageView}></Route>
        </Switch> */}
      </>
    );
  }
}

export default App;
