// export const countryMap = [
//     ["Afghanistan", "AF"],
//     ["Åland Islands", "AX"],
//     ["Albania", "AL"],
//     ["Algeria", "DZ"],
//     ["American Samoa", "AS"],
//     ["AndorrA", "AD"],
//     ["Angola", "AO"],
//     ["Anguilla", "AI"],
//     ["Antarctica", "AQ"],
//     ["Antigua and Barbuda", "AG"],
//     ["Argentina", "AR"],
//     ["Armenia", "AM"],
//     ["Aruba", "AW"],
//     ["Australia", "AU"],
//     ["Austria", "AT"],
//     ["Azerbaijan", "AZ"],
//     ["Bahamas", "BS"],
//     ["Bahrain", "BH"],
//     ["Bangladesh", "BD"],
//     ["Barbados", "BB"],
//     ["Belarus", "BY"],
//     ["Belgium", "BE"],
//     ["Belize", "BZ"],
//     ["Benin", "BJ"],
//     ["Bermuda", "BM"],
//     ["Bhutan", "BT"],
//     ["Bolivia", "BO"],
//     ["Bosnia and Herzegovina", "BA"],
//     ["Botswana", "BW"],
//     ["Bouvet Island", "BV"],
//     ["Brazil", "BR"],
//     ["British Indian Ocean Territory", "IO"],
//     ["Brunei Darussalam", "BN"],
//     ["Bulgaria", "BG"],
//     ["Burkina Faso", "BF"],
//     ["Burundi", "BI"],
//     ["Cambodia", "KH"],
//     ["Cameroon", "CM"],
//     ["Canada", "CA"],
//     ["Cape Verde", "CV"],
//     ["Cayman Islands", "KY"],
//     ["Central African Republic", "CF"],
//     ["Chad", "TD"],
//     ["Chile", "CL"],
//     ["China", "CN"],
//     ["Christmas Island", "CX"],
//     ["Cocos (Keeling) Islands", "CC"],
//     ["Colombia", "CO"],
//     ["Comoros", "KM"],
//     ["Congo", "CG"],
//     ["Congo, The Democratic Republic of the", "CD"],
//     ["Cook Islands", "CK"],
//     ["Costa Rica", "CR"],
//     ["Cote D\"Ivoire", "CI"],
//     ["Croatia", "HR"],
//     ["Cuba", "CU"],
//     ["Cyprus", "CY"],
//     ["Czech Republic", "CZ"],
//     ["Denmark", "DK"],
//     ["Djibouti", "DJ"],
//     ["Dominica", "DM"],
//     ["Dominican Republic", "DO"],
//     ["Ecuador", "EC"],
//     ["Egypt", "EG"],
//     ["El Salvador", "SV"],
//     ["Equatorial Guinea", "GQ"],
//     ["Eritrea", "ER"],
//     ["Estonia", "EE"],
//     ["Ethiopia", "ET"],
//     ["Falkland Islands (Malvinas)", "FK"],
//     ["Faroe Islands", "FO"],
//     ["Fiji", "FJ"],
//     ["Finland", "FI"],
//     ["France", "FR"],
//     ["French Guiana", "GF"],
//     ["French Polynesia", "PF"],
//     ["French Southern Territories", "TF"],
//     ["Gabon", "GA"],
//     ["Gambia", "GM"],
//     ["Georgia", "GE"],
//     ["Germany", "DE"],
//     ["Ghana", "GH"],
//     ["Gibraltar", "GI"],
//     ["Greece", "GR"],
//     ["Greenland", "GL"],
//     ["Grenada", "GD"],
//     ["Guadeloupe", "GP"],
//     ["Guam", "GU"],
//     ["Guatemala", "GT"],
//     ["Guernsey", "GG"],
//     ["Guinea", "GN"],
//     ["Guinea-Bissau", "GW"],
//     ["Guyana", "GY"],
//     ["Haiti", "HT"],
//     ["Heard Island and Mcdonald Islands", "HM"],
//     ["Holy See (Vatican City State)", "VA"],
//     ["Honduras", "HN"],
//     ["Hong Kong", "HK"],
//     ["Hungary", "HU"],
//     ["Iceland", "IS"],
//     ["India", "IN"],
//     ["Indonesia", "ID"],
//     ["Iran, Islamic Republic Of", "IR"],
//     ["Iraq", "IQ"],
//     ["Ireland", "IE"],
//     ["Isle of Man", "IM"],
//     ["Israel", "IL"],
//     ["Italy", "IT"],
//     ["Jamaica", "JM"],
//     ["Japan", "JP"],
//     ["Jersey", "JE"],
//     ["Jordan", "JO"],
//     ["Kazakhstan", "KZ"],
//     ["Kenya", "KE"],
//     ["Kiribati", "KI"],
//     ["Korea, Democratic People\"S Republic of", "KP"],
//     ["Korea, Republic of", "KR"],
//     ["Kuwait", "KW"],
//     ["Kyrgyzstan", "KG"],
//     ["Lao People\"S Democratic Republic", "LA"],
//     ["Latvia", "LV"],
//     ["Lebanon", "LB"],
//     ["Lesotho", "LS"],
//     ["Liberia", "LR"],
//     ["Libyan Arab Jamahiriya", "LY"],
//     ["Liechtenstein", "LI"],
//     ["Lithuania", "LT"],
//     ["Luxembourg", "LU"],
//     ["Macao", "MO"],
//     ["Macedonia, The Former Yugoslav Republic of", "MK"],
//     ["Madagascar", "MG"],
//     ["Malawi", "MW"],
//     ["Malaysia", "MY"],
//     ["Maldives", "MV"],
//     ["Mali", "ML"],
//     ["Malta", "MT"],
//     ["Marshall Islands", "MH"],
//     ["Martinique", "MQ"],
//     ["Mauritania", "MR"],
//     ["Mauritius", "MU"],
//     ["Mayotte", "YT"],
//     ["Mexico", "MX"],
//     ["Micronesia, Federated States of", "FM"],
//     ["Moldova, Republic of", "MD"],
//     ["Monaco", "MC"],
//     ["Mongolia", "MN"],
//     ["Montserrat", "MS"],
//     ["Morocco", "MA"],
//     ["Mozambique", "MZ"],
//     ["Myanmar", "MM"],
//     ["Namibia", "NA"],
//     ["Nauru", "NR"],
//     ["Nepal", "NP"],
//     ["Netherlands", "NL"],
//     ["Netherlands Antilles", "AN"],
//     ["New Caledonia", "NC"],
//     ["New Zealand", "NZ"],
//     ["Nicaragua", "NI"],
//     ["Niger", "NE"],
//     ["Nigeria", "NG"],
//     ["Niue", "NU"],
//     ["Norfolk Island", "NF"],
//     ["Northern Mariana Islands", "MP"],
//     ["Norway", "NO"],
//     ["Oman", "OM"],
//     ["Pakistan", "PK"],
//     ["Palau", "PW"],
//     ["Palestinian Territory, Occupied", "PS"],
//     ["Panama", "PA"],
//     ["Papua New Guinea", "PG"],
//     ["Paraguay", "PY"],
//     ["Peru", "PE"],
//     ["Philippines", "PH"],
//     ["Pitcairn", "PN"],
//     ["Poland", "PL"],
//     ["Portugal", "PT"],
//     ["Puerto Rico", "PR"],
//     ["Qatar", "QA"],
//     ["Reunion", "RE"],
//     ["Romania", "RO"],
//     ["Russian Federation", "RU"],
//     ["RWANDA", "RW"],
//     ["Saint Helena", "SH"],
//     ["Saint Kitts and Nevis", "KN"],
//     ["Saint Lucia", "LC"],
//     ["Saint Pierre and Miquelon", "PM"],
//     ["Saint Vincent and the Grenadines", "VC"],
//     ["Samoa", "WS"],
//     ["San Marino", "SM"],
//     ["Sao Tome and Principe", "ST"],
//     ["Saudi Arabia", "SA"],
//     ["Senegal", "SN"],
//     ["Serbia and Montenegro", "CS"],
//     ["Seychelles", "SC"],
//     ["Sierra Leone", "SL"],
//     ["Singapore", "SG"],
//     ["Slovakia", "SK"],
//     ["Slovenia", "SI"],
//     ["Solomon Islands", "SB"],
//     ["Somalia", "SO"],
//     ["South Africa", "ZA"],
//     ["South Georgia and the South Sandwich Islands", "GS"],
//     ["Spain", "ES"],
//     ["Sri Lanka", "LK"],
//     ["Sudan", "SD"],
//     ["Suriname", "SR"],
//     ["Svalbard and Jan Mayen", "SJ"],
//     ["Swaziland", "SZ"],
//     ["Sweden", "SE"],
//     ["Switzerland", "CH"],
//     ["Syrian Arab Republic", "SY"],
//     ["Taiwan, Province of China", "TW"],
//     ["Tajikistan", "TJ"],
//     ["Tanzania, United Republic of", "TZ"],
//     ["Thailand", "TH"],
//     ["Timor-Leste", "TL"],
//     ["Togo", "TG"],
//     ["Tokelau", "TK"],
//     ["Tonga", "TO"],
//     ["Trinidad and Tobago", "TT"],
//     ["Tunisia", "TN"],
//     ["Turkey", "TR"],
//     ["Turkmenistan", "TM"],
//     ["Turks and Caicos Islands", "TC"],
//     ["Tuvalu", "TV"],
//     ["Uganda", "UG"],
//     ["Ukraine", "UA"],
//     ["United Arab Emirates", "AE"],
//     ["United Kingdom", "GB"],
//     ["United States", "US"],
//     ["United States Minor Outlying Islands", "UM"],
//     ["Uruguay", "UY"],
//     ["Uzbekistan", "UZ"],
//     ["Vanuatu", "VU"],
//     ["Venezuela", "VE"],
//     ["Viet Nam", "VN"],
//     ["Virgin Islands, British", "VG"],
//     ["Virgin Islands, U.S.", "VI"],
//     ["Wallis and Futuna", "WF"],
//     ["Western Sahara", "EH"],
//     ["Yemen", "YE"],
//     ["Zambia", "ZM"],
//     ["Zimbabwe", "ZW"]
// ];




export const countryMap = [
    ["1", "Batman"],
    ["2", "Heist"],
    ["3", "Transportation"],
    ["4", "Moddable"],
    ["5", "TrackIR"],
    ["6", "Star Wars"],
    ["7", "4X"],
    ["8", "Dynamic Narration"],
    ["9", "LEGO"],
    ["10", "Rome"],
    ["11", "Wrestling"],
    ["12", "Base Building"],
    ["13", "Open World"],
    ["14", "Grand Strategy"],
    ["15", "Motorbike"],
    ["16", "Immersive Sim"],
    ["17", "CRPG"],
    ["18", "Trading"],
    ["19", "Mod"],
    ["20", "Crafting"],
    ["21", "Kickstarter"],
    ["22", "Tactical RPG"],
    ["23", "Masterpiece"],
    ["24", "Submarine"],
    ["25", "Games Workshop"],
    ["26", "Souls-like"],
    ["27", "Gothic"],
    ["28", "Time Travel"],
    ["29", "Mythology"],
    ["30", "Multiplayer"],
    ["31", "Building"],
    ["32", "Real-Time with Pause"],
    ["33", "Economy"],
    ["34", "Villain Protagonist"],
    ["35", "Cycling"],
    ["36", "Dating Sim"],
    ["37", "City Builder"],
    ["38", "Hack and Slash"],
    ["39", "Artificial Intelligence"],
    ["40", "Character Customization"],
    ["41", "Military"],
    ["42", "Co-op"],
    ["43", "Isometric"],
    ["44", "RTS"],
    ["45", "Asynchronous Multiplayer"],
    ["46", "Management"],
    ["47", "Crowdfunded"],
    ["48", "NSFW"],
    ["49", "Historical"],
    ["50", "eSports"],
    ["51", "Mature"],
    ["52", "Epic"],
    ["53", "Real Time Tactics"],
    ["54", "Tactical"],
    ["55", "Trains"],
    ["56", "Cult Classic"],
    ["57", "Realistic"],
    ["58", "Offroad"],
    ["59", "World War II"],
    ["60", "World War I"],
    ["61", "Turn-Based Strategy"],
    ["62", "Medieval"],
    ["63", "Warhammer 40K"],
    ["64", "Bikes"],
    ["65", "Mars"],
    ["66", "Cold War"],
    ["67", "Third Person"],
    ["68", "Strategy RPG"],
    ["69", "Sandbox"],
    ["70", "Mechs"],
    ["71", "Action RPG"],
    ["72", "Fishing"],
    ["73", "Diplomacy"],
    ["74", "Dungeon Crawler"],
    ["75", "Capitalism"],
    ["76", "Hex Grid"],
    ["77", "Loot"],
    ["78", "Post-apocalyptic"],
    ["79", "Voice Control"],
    ["80", "Pool"],
    ["81", "Agriculture"],
    ["82", "Resource Management"],
    ["83", "Turn-Based Tactics"],
    ["84", "First-Person"],
    ["85", "Inventory Management"],
    ["86", "Steampunk"],
    ["87", "Surreal"],
    ["88", "Driving"],
    ["89", "3D Vision"],
    ["90", "Time Manipulation"],
    ["91", "Investigation"],
    ["92", "Lore-Rich"],
    ["93", "Politics"],
    ["94", "Rogue-like"],
    ["95", "Stealth"],
    ["96", "Naval"],
    ["97", "Walking Simulator"],
    ["98", "Dystopian"],
    ["99", "Wargame"],
    ["100", "Tennis"],
    ["101", "Local Co-Op"],
    ["102", "Choices Matter"],
    ["103", "Real-Time"],
    ["104", "Cinematic"],
    ["105", "Parkour"],
    ["106", "God Game"],
    ["107", "Programming"],
    ["108", "Space Sim"],
    ["109", "Parody"],
    ["110", "Rogue-lite"],
    ["111", "Emotional"],
    ["112", "Great Soundtrack"],
    ["113", "Classic"],
    ["114", "Otome"],
    ["115", "Swordplay"],
    ["116", "Nature"],
    ["117", "Turn-Based Combat"],
    ["118", "Football"],
    ["119", "Female Protagonist"],
    ["120", "War"],
    ["121", "Team-Based"],
    ["122", "Beat 'em up"],
    ["123", "Metroidvania"],
    ["124", "Touch-Friendly"],
    ["125", "Perma Death"],
    ["126", "Bullet Time"],
    ["127", "Illuminati"],
    ["128", "Procedural Generation"],
    ["129", "Cyberpunk"],
    ["130", "FPS"],
    ["131", "Story Rich"],
    ["132", "Psychological Horror"],
    ["133", "Class-Based"],
    ["134", "Exploration"],
    ["135", "Third-Person Shooter"],
    ["136", "Faith"],
    ["137", "MMORPG"],
    ["138", "Singleplayer"],
    ["139", "Grid-Based Movement"],
    ["140", "Split Screen"],
    ["141", "Dark Fantasy"],
    ["142", "Hockey"],
    ["143", "Survival"],
    ["144", "Narration"],
    ["145", "Fighting"],
    ["146", "JRPG"],
    ["147", "Satire"],
    ["148", "LGBTQ+"],
    ["149", "Romance"],
    ["150", "Mining"],
    ["151", "Quick-Time Events"],
    ["152", "Anime"],
    ["153", "Cats"],
    ["154", "2D Fighter"],
    ["155", "Rhythm"],
    ["156", "America"],
    ["157", "1990's"],
    ["158", "Destruction"],
    ["159", "Co-op Campaign"],
    ["160", "Spectacle fighter"],
    ["161", "Dark Humor"],
    ["162", "Detective"],
    ["163", "Ninja"],
    ["164", "Mystery"],
    ["165", "Aliens"],
    ["166", "Vampire"],
    ["167", "Sniper"],
    ["168", "Multiple Endings"],
    ["169", "Sci-fi"],
    ["170", "Funny"],
    ["171", "Robots"],
    ["172", "Noir"],
    ["173", "FMV"],
    ["174", "Steam Machine"],
    ["175", "2.5D"],
    ["176", "PvP"],
    ["177", "Atmospheric"],
    ["178", "Memes"],
    ["179", "Golf"],
    ["180", "Alternate History"],
    ["181", "Flight"],
    ["182", "Pirates"],
    ["183", "Controller"],
    ["184", "Space"],
    ["185", "Lovecraftian"],
    ["186", "Shooter"],
    ["187", "Hacking"],
    ["188", "Board Game"],
    ["189", "Dragons"],
    ["190", "Local Multiplayer"],
    ["191", "Level Editor"],
    ["192", "Voxel"],
    ["193", "Dark"],
    ["194", "Sailing"],
    ["195", "Cute"],
    ["196", "Addictive"],
    ["197", "Gun Customization"],
    ["198", "Magic"],
    ["199", "Psychological"],
    ["200", "Underwater"],
    ["201", "Visual Novel"],
    ["202", "Survival Horror"],
    ["203", "Soccer"],
    ["204", "Horses"],
    ["205", "Hand-drawn"],
    ["206", "Futuristic"],
    ["207", "Assassin"],
    ["208", "Comedy"],
    ["209", "Point & Click"],
    ["210", "Character Action Game"],
    ["211", "Martial Arts"],
    ["212", "Beautiful"],
    ["213", "Dinosaurs"],
    ["214", "PvE"],
    ["215", "Game Development"],
    ["216", "Competitive"],
    ["217", "Transhumanism"],
    ["218", "6DOF"],
    ["219", "Demons"],
    ["220", "Political"],
    ["221", "Typing"],
    ["222", "Tanks"],
    ["223", "Side Scroller"],
    ["224", "Horror"],
    ["225", "4 Player Local"],
    ["226", "Top-Down"],
    ["227", "Dog"],
    ["228", "Blood"],
    ["229", "Conspiracy"],
    ["230", "Philisophical"],
    ["231", "On-Rails Shooter"],
    ["232", "Basketball"],
    ["233", "3D"],
    ["234", "2D"],
    ["235", "Baseball"],
    ["236", "Pixel Graphics"],
    ["237", "Replay Value"],
    ["238", "Physics"],
    ["239", "Mini Golf"],
    ["240", "Animation & Modeling"],
    ["241", "Science"],
    ["242", "Difficult"],
    ["243", "GameMaker"],
    ["244", "Hunting"],
    ["245", "Supernatural"],
    ["246", "Zombies"],
    ["247", "Dark Comedy"],
    ["248", "Card Game"],
    ["249", "Video Production"],
    ["250", "3D Platformer"],
    ["251", "Psychedelic"],
    ["252", "Time Attack"],
    ["253", "Superhero"],
    ["254", "Nonlinear"],
    ["255", "Bullet Hell"],
    ["256", "Photo Editing"],
    ["257", "Experimental"],
    ["258", "Twin Stick Shooter"],
    ["259", "Nudity"],
    ["260", "Silent Protagonist"],
    ["261", "Underground"],
    ["262", "Werewolves"],
    ["263", "Unforgiving"],
    ["264", "Spelling"],
    ["265", "Retro"],
    ["266", "Linear"],
    ["267", "Music-Based Procedural Generation"],
    ["268", "Minigames"],
    ["269", "Choose Your Own Adventure"],
    ["270", "Short"],
    ["271", "Battle Royale"],
    ["272", "Family Friendly"],
    ["273", "Colorful"],
    ["274", "Sexual Content"],
    ["275", "Pinball"],
    ["276", "Shoot 'Em Up"],
    ["277", "Software Training"],
    ["278", "Education"],
    ["279", "Fantasy"],
    ["280", "Puzzle"],
    ["281", "Platformer"],
    ["282", "Fast-Paced"],
    ["283", "Conversation"],
    ["284", "Interactive Fiction"],
    ["285", "Cartoony"],
    ["286", "Gore"],
    ["287", "Cartoon"],
    ["288", "Chess"],
    ["289", "Arena Shooter"],
    ["290", "Early Access"],
    ["291", "Intentionally Awkward Controls"],
    ["292", "Experience"],
    ["293", "Tower Defense"],
    ["294", "Comic Book"],
    ["295", "Arcade"],
    ["296", "Western"],
    ["297", "Top-Down Shooter"],
    ["298", "Trading Card Game"],
    ["299", "Crime"],
    ["300", "MOBA"],
    ["301", "Audio Production"],
    ["302", "Action-Adventure"],
    ["303", "Music"],
    ["304", "1980s"],
    ["305", "Mystery Dungeon"],
    ["306", "Based On A Novel"],
    ["307", "Lemmings"],
    ["308", "Relaxing"],
    ["309", "Mouse only"],
    ["310", "Puzzle-Platformer"],
    ["311", "Word Game"],
    ["312", "Solitaire"],
    ["313", "Stylized"],
    ["314", "Simulation"],
    ["315", "Turn-Based"],
    ["316", "Automation"],
    ["317", "Motocross"],
    ["318", "Sequel"],
    ["319", "Time Management"],
    ["320", "Violent"],
    ["321", "Massively Multiplayer"],
    ["322", "Adventure"],
    ["323", "RPG"],
    ["324", "Strategy"],
    ["325", "Action"],
    ["326", "Sokoban"],
    ["327", "Match 3"],
    ["328", "Racing"],
    ["329", "VR"],
    ["330", "Sports"],
    ["331", "Episodic"],
    ["332", "Design & Illustration"],
    ["333", "Runner"],
    ["334", "Drama"],
    ["335", "Minimalist"],
    ["336", "Score Attack"],
    ["337", "Clicker"],
    ["338", "Hidden Object"],
    ["339", "RPGMaker"],
    ["340", "Online Co-Op"],
    ["341", "Soundtrack"],
    ["342", "Indie"],
    ["343", "Abstract"],
    ["344", "Thriller"],
    ["345", "Modern"],
    ["346", "Text-Based"],
    ["347", "Gambling"],
    ["348", "Old School"],
    ["349", "Utilities"],
    ["350", "Logic"],
    ["351", "Casual"],
    ["352", "Gaming"],
    ["353", "Web Publishing"],
    ["354", "Dungeons & Dragons"],
    ["355", "360 Video"],
    ["356", "Free to Play"],
    ["357", "Documentary"],
    ["358", "Feature Film"],
    ["359", "Party-Based RPG"],
    ["360", "Remake"],
    ["361", "Benchmark"],
    ["362", "Tutorial"],
    ["363", "Software"],
    ["364", "Movie"]
];