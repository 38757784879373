import { API_BASE_URL, ACCESS_TOKEN } from "../../Constants"
import { api, apiHeader } from "../../Configs/axiosConfig"
import { defineCancelApiObject } from "../../Configs/axiosUtils"

export const LikesAPI = {
    like: async function (gameId, cancel = false) {
        const response = await api.request({
            url: "/likes/like",
            method: "POST",
            params: {
                gameId: gameId,
            },

            headers: apiHeader,

            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        // console.log(response);
    },

    unlike: async function (gameId, cancel = false) {
        const response = await api.request({
            url: "/likes/unlike",
            method: "POST",
            params: {
                gameId: gameId,
            },

            headers: apiHeader,

            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        // console.log(response);
    },

    exist: async function (gameId, cancel = false) {
        const response = await api.request({
            url: "/likes/exist",
            method: "POST",
            params: {
                gameId: gameId,
            },

            headers: apiHeader,

            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        // alert(response);
        return response;
    },
}

// defining the cancel API object for GameAPI
const cancelApiObject = defineCancelApiObject(LikesAPI)