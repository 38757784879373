import { API_BASE_URL } from "../../Constants"
import { api, apiHeader } from "../../Configs/axiosConfig"
import { defineCancelApiObject } from "../../Configs/axiosUtils"

export const InteractionsAPI = {
    addDetailView: async function (itemId, cancel = false) {
        const response = await api.request({


            url: "/Interaction/addDetailView",
            method: "POST",

            params: {
                itemId: itemId,
                // recommendId:
            },

            headers: apiHeader,
            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        console.log(response.status);
    },


    addRating: async function (itemId, cancel = false) {
        const response = await api.request({


            url: "/Interaction/addRating",
            method: "POST",

            params: {
                itemId: itemId,
            },

            headers: apiHeader,
            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        console.log(response.status);
    },

    addCartAddition: async function (itemId, cancel = false) {
        const response = await api.request({


            url: "/Interaction/CartAddition",
            method: "POST",

            params: {
                itemId: itemId,
            },

            headers: apiHeader,
            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        console.log(response.status);
    },


    addPurchase: async function (itemId, cancel = false) {
        const response = await api.request({


            url: "/Interaction/Purchase",
            method: "POST",

            params: {
                itemId: itemId,
            },

            headers: apiHeader,
            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        console.log(response.status);
    },

}

// defining the cancel API object for InteractionsAPI
const cancelApiObject = defineCancelApiObject(InteractionsAPI)