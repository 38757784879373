// src/redux/store.js

import { createStore } from 'redux';

const initialState = {
    activeGameTag: "All",
    tagGames: [],
    themeModal: false
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_ACTIVE_GAME_TAG':
            return { ...state, activeGameTag: action.payload };
        case 'SET_TAG_GAMES':
            return { ...state, tagGames: action.payload };
        case 'SET_THEME_MODAL':
            return { ...state, themeModal: action.payload };
        default:
            return state;
    }
}

const store = createStore(rootReducer);

export default store;
