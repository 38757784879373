import { API_BASE_URL } from "../../Constants"
import { api, apiHeader } from "../../Configs/axiosConfig"
import { defineCancelApiObject } from "../../Configs/axiosUtils"

export const GameSearchRecommendationAPI = {
    searchGamesByPersonalization: async function (searchQuery, count, cancel = false) {
        const response = await api.request({
            url: "/SearchRecommend/personal/" + searchQuery + "/" + count,
            method: "GET",

            withCredentials: true,
            headers: apiHeader,

            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response.data;
    },
    searchGamesByNonPersonalization: async function (searchQuery, count, cancel = false) {
        const response = await api.request({
            url: "/SearchRecommend/non-personal/" + searchQuery + "/" + count,
            method: "GET",

            withCredentials: true,
            headers: apiHeader,

            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response.data;
    },




}

// defining the cancel API object for GameSearchRecommendationAPI
const cancelApiObject = defineCancelApiObject(GameSearchRecommendationAPI)