import React, { useEffect, useState } from 'react'

export default function NextPageView(props) {
    const [gameSource, setGameSource] = useState(props.location.state.gameSource);

    useEffect(() => {
        console.log(props.location.state.gameSource);
    }, [])


    const styles = {
        iframe: {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            border: 'none',
            zIndex: '99999999999999'
        },
    };


    return (
        <iframe
            style={styles.iframe}
            frameborder="0"
            allowfullscreen="true" scrolling="no"
            allow="autoplay; fullscreen *; geolocation; microphone; camera; midi; monetization; xr-spatial-tracking; gamepad; gyroscope; accelerometer; xr; cross-origin-isolated"
            allowtransparency="true" webkitallowfullscreen="true" mozallowfullscreen="true"
            msallowfullscreen="true"

            src={gameSource}
            // src="https://v6p9d9t4.ssl.hwcdn.net/html/2726184/index.html"
            id="game_drop">

        </iframe>
    )
}
