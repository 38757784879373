import React, { Component, useState } from 'react';
import '../Home.css';



import Carousel from 'react-bootstrap/Carousel';

function ControlledCarousel() {

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };


  return (
    <>

      <Carousel className='pt-4 slide-in-bottom-item ' interval={5000} activeIndex={index} onSelect={handleSelect} >

        <Carousel.Item className='ratio ratio-21x9' style={{ borderRadius: "20px" }}>
          <video onEnded={handleSelect} className="d-block w-100 feature-banner-img" loop autoPlay muted src="videos\CubeRunner_Portfolio.mp4"></video>
        </Carousel.Item>

        <Carousel.Item className='ratio ratio-21x9' >
          <video onEnded={handleSelect} className="d-block w-100 feature-banner-img" loop autoPlay muted src="videos/rdr_2_feature_480p.mp4"></video>

        </Carousel.Item>
        <Carousel.Item className='ratio ratio-21x9' >
          <video onEnded={handleSelect} className="d-block w-100 feature-banner-img" loop autoPlay muted src="videos/Feature_Video.mp4"></video>

        </Carousel.Item>
      </Carousel >
    </>
  );
}
const Banner = (props) => {
  return (
    ControlledCarousel()

  );
}
export default Banner;