import React, { useState } from 'react';
import { useEffect } from 'react';
import Alert from 'react-s-alert';

import { CircularProgressbar } from 'react-circular-progressbar';

import { API_BASE_URL, ACCESS_TOKEN } from '../../API/Constants';
import MyRichTextEditor from "./Components/RichTextEditor";
import { TagInput } from './Components/TagInput/TagInput';

import 'react-circular-progressbar/dist/styles.css';
import "./UploadGame.css";
import { GameCoverAPI } from '../../API/Endpoints/Backend/Game/GameCoverAPI';

import axios from 'axios';
import { GameVideoAPI } from '../../API/Endpoints/Backend/Game/GameVideoAPI';
import { GameScreenshotsAPI } from '../../API/Endpoints/Backend/Game/GameScreenshotsAPI';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
// import MUIRichTextEditor from 'mui-rte';

import ProgressBar from "@ramonak/react-progress-bar";




const UploadGame = (props) => {

    // [Deprecated]
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('id');
    const [theGame, setTheGame] = useState("");



    const [gameToModify, setGameToModify] = useState((props.location.state != undefined) ? props.location.state.gameToModify : null);
    const [text, setText] = useState("Upload");



    // Field Variables
    const [gameTitle, setGameTitle] = useState("");
    // const [gameDescription, setGameDescription] = useState((props.location.state != undefined) ? decodeURI(gameToModify.gameDescription) : null);
    const [gameDescription, setGameDescription] = useState("");
    const [gameReleaseStatus, setGameReleaseStatus] = useState("");
    const [gameGenre, setGameGenre] = useState("");
    const [gameTags, setGameTags] = useState([]);
    const [gameUploadGame, setGameUploadGame] = useState(null);
    const [gameUploadCover, setGameUploadCover] = useState(null);

    const [gameUploadScreenshots, setGameUploadScreenshots] = useState();

    const [gameVisibility, setGameVisibility] = useState("");
    const [gameAudience, setGameAudience] = useState("");
    const [value, setValue] = useState('');


    const [videoProgress, setVideoProgress] = useState(0);

    const [filesProgress, setfilesProgress] = useState(0);

    useEffect(() => {

        if (gameToModify != null) {
            console.log(gameToModify);
            FillFields();
        }

        // if (id) {
        //     fetch(
        //         API_BASE_URL + "/game/one/" + id)
        //         .then((res) => res.json())
        //         .then((json) => {
        //             setTheGame(json);

        //             console.log(theGame);
        //             FillFields();
        //         })
        // }

    }, [])


    function FillFields() {
        setText("Update");
        setGameTitle(gameToModify.gameTitle);
        // setGameDescription(decodeURI(gameToModify.gameDescription));
        // console.log(decodeURI(gameToModify.gameDescription));
        // encodeURI(gameDescription)
        setGameGenre(gameToModify.genreName);
        setGameUploadCover(gameToModify.cover);

        ExistingVideo();
        ExistingCover();
        ExistingScreenshot();

        // RichTextEditorText(gameToModify.gameDescription);

        // document.getElementById('textEditor').value = gameDescription;
        // OnImageUpload();

    }



    const RichTextEditorText = val => {
        setGameDescription(String(val));
        // console.log(gameDescription);
    }



    const genres = [
        "Action",
        "Adventure",
        "Card Game",
        "Educational",
        "Fighting",
        "Interactive Fiction",
        "Platformer",
        "Puzzle",
        "Racing",
        "Rhythm",
        "Role Playing",
        "Shooter",
        "Simulation",
        "Sports",
        "Strategy",
        "Survival",
        "VisualNovel",
        "Other"
    ]



    const OnImageUpload = (e) => {
        var file = e.target.files[0];
        let reader = new FileReader();
        var box = document.getElementById('coverBox');
        box.innerHTML = "";

        reader.readAsDataURL(file);
        reader.onload = () => {
            var myImg = document.createElement('img');
            myImg.src = reader.result;

            myImg.classList.add("mb-3");
            myImg.classList.add("rounded-3");


            box.appendChild(myImg);


            //testing purpose
            UpdateCoverSeparately();
        }
    }


    const OnScreenshotUpload = (e) => {

        console.log(e.target.files)

        var box = document.getElementById('screenshotBox');
        box.innerHTML = "";


        for (let i = 0; i < e.target.files.length; i++) {
            const file = e.target.files[i];


            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {

                var myImg = document.createElement('img');

                myImg.src = reader.result;
                myImg.classList.add("mb-3");
                myImg.classList.add("rounded-3");

                box.appendChild(myImg);




            }

        }
        //Temporary Checking
        UpdateScreenshotSeparately();



    }




    const OnVideoUpload = (e) => {

        var file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);

        var box = document.getElementById('videoBox');
        // box.innerHTML = "";
        var divv = document.getElementById('cp');
        divv.className = "d-block";

        reader.onprogress = (data) => {
            if (data.lengthComputable) {
                setVideoProgress(parseInt(((data.loaded / data.total) * 100), 10));
            }

        }
        reader.onloadend = () => {

            var myVid = document.createElement('video');

            myVid.src = reader.result;
            myVid.autoplay = true;
            myVid.loop = true;
            myVid.muted = true;
            myVid.load();

            myVid.classList.add("mb-3");
            myVid.classList.add("rounded-3");
            myVid.classList.add("w-100");

            myVid.onloadeddata = () => {

                divv.className = "d-none";

                box.innerHTML = "";
                box.appendChild(divv);
                box.appendChild(myVid);

                //testing purpose
                UpdateVideoSeparately();
            }

        }
    }




    const ExistingCover = () => {

        var box = document.getElementById('coverBox');
        box.innerHTML = "";

        var myImg = document.createElement('img');
        myImg.src = gameToModify.cover;

        myImg.classList.add("mb-3");
        myImg.classList.add("rounded-3");

        box.appendChild(myImg);

    }

    const ExistingVideo = () => {
        var box = document.getElementById('videoBox');
        var divv = document.getElementById('cp');
        divv.className = "d-block";

        if (gameToModify.video != undefined) {
            var myVid = document.createElement('video');

            myVid.src = gameToModify.video;
            myVid.autoplay = true;
            myVid.loop = true;
            myVid.muted = true;
            myVid.load();

            myVid.classList.add("mb-3");
            myVid.classList.add("rounded-3");
            myVid.classList.add("w-100");
            divv.className = "d-none";

            box.innerHTML = "";
            box.appendChild(divv);
            box.appendChild(myVid);

            myVid.onloadeddata = () => {

                // divv.className = "d-none";

                // box.innerHTML = "";
                // box.appendChild(divv);
                // box.appendChild(myVid);
            }
        }

    }



    const ExistingScreenshot = () => {

        // console.log(e.target.files)

        var box = document.getElementById('screenshotBox');
        box.innerHTML = "";



        for (let i = 0; i < gameToModify.gameScreenShotList.length; i++) {
            var myImg = document.createElement('img');

            myImg.src = gameToModify.gameScreenShotList[i].screenShot;
            myImg.classList.add("mb-3");
            myImg.classList.add("rounded-3");

            box.appendChild(myImg);

        }
        //Temporary Checking
        // UpdateScreenshotSeparately();

    }

    const mysave = (data) => {
        console.log(data);
    };

    const myTheme = createTheme({
        // Set up your custom MUI theme here
    });

    const OnFilesUpload = (e) => {

        var files = e.target.files;
        console.log(files);


        var box = document.getElementById('filesBox');
        box.innerHTML = "";


        // <i class="fa-regular fa-file"></i>

        for (let i = 0; i < files.length; i++) {
            // var myImg = document.createElement('img');

            // myImg.src = gameToModify.gameScreenShotList[i].screenShot;
            // myImg.classList.add("mb-3");
            // myImg.classList.add("rounded-3");

            var file =
                `
            <span class="w-25" style="display: flex; flex-direction: column; align-items: center; margin: 10px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                <i class="fa-solid fa-file" style="font-size: 40px; color: var(--red)"></i>
                <div class="text-white text-center" style="width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">${files[i].name}</div>
            </span>`;

            box.innerHTML += file;

        }

    }




    function Output() {
        if (filesProgress == 100) {
            Alert.success("Game Uploaded");
            props.history.push('/');
        }
        return (
            <div className="container upload-form p-5 appheader-margin slide-in-bottom">
                <div id="liveAlertPlaceholder"></div>
                <div className="row slide-in-bottom-item">
                    <div className='col-lg-8'>
                        <div className="form-group text-white mb-4">
                            <label data-testid="gameTitle" htmlFor='gameTitle'>Game Title</label>
                            <input required
                                type="text"
                                autoFocus
                                maxLength={30}
                                onChange={event => {
                                    setGameTitle(event.target.value);
                                }}
                                value={gameTitle}
                                name="gameTitle"
                                className="form-control bg-dark  text-white"
                                id="gameTitle"
                                placeholder="Enter your game title"
                            />
                            <div class="invalid-feedback">
                                Please provide a vaid GameTitle.
                            </div>
                        </div>

                        <MyRichTextEditor onTextChange={RichTextEditorText} editorText={gameDescription} />


                        <div className="form-group  text-white mb-4">
                            <label htmlFor="gameReleaseStatus">Release Status</label><br />
                            <small className='text-white-50'>Identify your current project status</small>

                            <select onChange={event => setGameReleaseStatus(event.target.value)} value={gameReleaseStatus} className='form-control bg-dark  text-white' name="gameReleaseStatus" id="gameReleaseStatus">
                                <option value="Released">Released</option>
                                <option value="Prototype">Prototype</option>
                            </select>

                        </div>



                        <div className="form-group text-white mb-4">
                            <label htmlFor="gameGenre">Genre</label><br />
                            <small className='text-white-50'>Select the category that best describes your game. You can pick additional genres with tags below</small>
                            <select onChange={event => setGameGenre(event.target.value)} value={gameGenre} name="genreName" id='genreName' className='form-control bg-dark text-white'>
                                {
                                    genres.map(val => (
                                        <option key={val} value={val}>{val}</option>
                                    ))
                                }
                            </select>
                        </div>


                        <div className="form-group text-white mb-4">
                            <label htmlFor="gameTags">Tags</label><br />
                            <small className='text-white-50'>Any other keywords someone might search to find your game. Max of 10.<br />Avoid using the genre or platforms provided above.</small>


                            <TagInput />
                        </div>

                        {/* <div className="form-group text-white mb-4">
                            <label htmlFor="gameUploadGame">{text} Game</label>
                            <input directory="" webkitdirectory="" multiple onChange={event => setGameUploadGame(event.target.files)} type="file" name="gameUploadGame" id="gameUploadGame" className='form-control' />
                            <div id='uploadArea'></div>
                        </div> */}



                        {
                            (text == "Update") ?
                                <button id="liveAlertBtn" onClick={event => save(event)} className="btn form-control" >{text} My Game</button>
                                :
                                <button id="liveAlertBtn" onClick={event => handleSubmit(event)} className="btn form-control" style={{ backgroundColor: "var(--red)", border: "2px solid white", color: "white" }} >Upload My Game</button>


                        }

                        {
                            (filesProgress > 0) ?
                                <div className="form-group text-white mb-3 mt-3">
                                    <label htmlFor="gameTags">Upload Progress</label><br />
                                    {/* <small className='text-white-50'>Any other keywords someone might search to find your game. Max of 10.<br />Avoid using the genre or platforms provided above.</small> */}
                                    <ProgressBar bgColor='var(--red)' className='progress-bar-border' borderRadius='10px' completed={filesProgress} />
                                </div>

                                : <></>

                        }
                    </div>





                    <div className='col-lg-4'>


                        <div className='form-group text-white mb-4'>

                            <div className="row d-flex align-items-end">


                                <div className='w-100 p-4  rounded-4 mt-2 primary-bg'>
                                    <div id='coverBox'>
                                        <div className='w-100 ratio-16x9 mb-3 p-2 rounded-3 placeholderImage' >
                                            <div className="text-center">
                                                Recommended (Minimum: 315x250)
                                            </div>
                                        </div>
                                    </div>

                                    <label htmlFor='gameUploadCover' className='w-100 text-center btn'> {text} Cover
                                        <input accept='image/*' onChange={event => OnImageUpload(event)} name='gameUploadCover' id='gameUploadCover' type="file" />
                                    </label>
                                </div>

                                <div className='w-100 p-4  rounded-4 mt-2 primary-bg'>
                                    <div id='screenshotBox'>
                                        {/* <div className='w-100 ratio-16x9 mb-3 rounded-3 placeholderImage' ></div>
                                    <div className='w-100 ratio-16x9 mb-3 rounded-3 placeholderImage' ></div>
                                    <div className='w-100 ratio-16x9 mb-3 rounded-3 placeholderImage' ></div> */}

                                    </div>

                                    <label htmlFor='gameUploadScreenshots' className='w-100 text-center btn'> {text} Screenshot
                                        <input multiple accept='image/*' onChange={event => OnScreenshotUpload(event)} name='gameUploadScreenshots' id='gameUploadScreenshots' type="file" />
                                    </label>
                                </div>

                                <div className='w-100 p-4  rounded-4 mt-2 primary-bg'>
                                    <div id='videoBox'>
                                        {/* <div className='w-100 ratio-16x9 mb-3 rounded-3 placeholderImage' ></div> */}

                                        <div id='cp' className='d-none'>
                                            <CircularProgressbar className='w-100 p-4' value={videoProgress} text={`${videoProgress}% `} />

                                        </div>

                                    </div>

                                    <label htmlFor='gameUploadVideo' className='w-100 text-center btn'> {text} Video
                                        <input accept="video/mp4,video/mkv, video/x-m4v,video/*" onChange={event => OnVideoUpload(event)} name='gameUploadVideo' id='gameUploadVideo' type="file" />
                                    </label>
                                </div>

                                <div className='w-100 p-4 rounded-4 mt-2 mb-4 primary-bg'>
                                    <div id="filesBox" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>

                                    </div>

                                    <label htmlFor='gameUploadGame' className='w-100 text-center btn'> {text} Game
                                        <input directory="" webkitdirectory="" multiple onChange={event => OnFilesUpload(event)} type="file" name="gameUploadGame" id="gameUploadGame" className='form-control' />
                                        <div id='uploadArea'></div>
                                        {/* <input accept='image/*' onChange={event => OnImageUpload(event)} name='gameUploadCover' id='gameUploadCover' type="file" /> */}
                                    </label>

                                    <div id="progressBox">
                                        {
                                            (filesProgress > 0) ?
                                                <ProgressBar bgColor='var(--red)' className='progress-bar-border mt-3' borderRadius='10px' completed={filesProgress} />
                                                : <></>

                                        }

                                        {/* <h3></h3> */}

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>


                </div>





                {/* </form> */}


            </div >
        );
    }


    const UpdateCoverSeparately = () => {
        const formData = new FormData();
        // formData.append('file', file);
        formData.append("gameId", gameToModify.gameId);
        formData.append("gameUploadCover", document.getElementById("gameUploadCover").files[0])

        // fetch(API_BASE_URL + "/gameCover/update", {
        //     method: 'PUT',
        //     body: formData,
        //     headers: {
        //         "Access-Control-Allow-Origin": "*",
        //         'Content-Type': 'multipart/form-data',
        //         "Authorization": 'Bearer ' + localStorage.getItem(ACCESS_TOKEN)
        //         // 'Authorization': 'Bearer ' + YOUR_ACCESS_TOKEN
        //     }
        // })
        //     .then(response => response.json())
        //     .then(data => {
        //         console.log(data);
        //         // Handle the response data here
        //     })
        //     .catch(error => {
        //         console.log("Core pr Aya Ha")
        //         // Handle errors here
        //     });

        GameCoverAPI.updateCover(formData)
            .then(response => {
                console.log(response);
                if (response.status == 200)
                    Alert.success("Cover Updated Successfully!");
            })
            .catch(error => {
                console.error(error);
                Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });

    }


    const UpdateVideoSeparately = () => {
        const formData = new FormData();
        // formData.append('file', file);
        formData.append("gameId", gameToModify.gameId);
        // formData.append("gameUploadCover", document.getElementById("gameUploadCover").files[0])
        formData.append("gameUploadVideo", document.getElementById("gameUploadVideo").files[0])

        GameVideoAPI.updateVideo(formData)
            .then(response => {
                // console.log(response.status);
                if (response.status == 200)
                    Alert.success("Video Updated Successfully!");

            })
            .catch(error => {
                console.error(error);
                Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });

    }

    const UpdateScreenshotSeparately = () => {
        const formData = new FormData();
        // formData.append('file', file);
        formData.append("gameId", gameToModify.gameId);



        let gameScreenshotInput = document.getElementById("gameUploadScreenshots").files;
        for (const gameScreen of gameScreenshotInput)
            formData.append("gameUploadScreenshots", gameScreen)


        GameScreenshotsAPI.updateScreenshots(formData)
            .then(response => {
                if (response.status == 200)
                    Alert.success("Screenshots Updated Successfully!");
            })
            .catch(error => {
                console.error(error);
                Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });

    }









    const check = val => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'gameTitle': val
            },
            // body: JSON.stringify({ gameTitle: "astray" })
        };

        var check;

        fetch(API_BASE_URL + "/game/isExist", requestOptions)
            .then(response => {
                check = response.json();
                console.log(check);
            });
    }


    function configureAlert(heading, message, type) {

        const alertPlaceholder = document.getElementById('liveAlertPlaceholder')

        const alert = (heading, message, type) => {
            const wrapper = document.createElement('div')
            wrapper.innerHTML = [
                `<div class="alert alert-dark alert-dismissible" role="alert">
                    <h4 class="alert-heading">${heading}</h4>
                    <div>${message}</div>
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>`
            ].join('')

            alertPlaceholder.childNodes.forEach(value => value.remove())
            alertPlaceholder.append(wrapper)
        }

        const alertTrigger = document.getElementById('liveAlertBtn')
        if (alertTrigger) {
            alertTrigger.addEventListener('click', () => {
                alert(heading, message, type)
            })
        }
        alert(heading, message, type)
    }

    const DisableSubmitButton = (b) => {
        document.getElementById("liveAlertBtn").disabled = b;
    }


    const handleSubmit = event => {

        DisableSubmitButton(true);
        configureAlert('Verifying Build', 'Please wait while we verify your game build for moderation. This may take a few minutes.', 'info');

        let request = new XMLHttpRequest();
        request.open("POST", API_BASE_URL + "/game/upload", true)


        request.onprogress = function (event) {
            if (event.lengthComputable) {
                var percentComplete = (event.loaded / event.total) * 100;
                // Update the progress bar or display the progress percentage
                console.log('Progress: ' + percentComplete + '%');
            }
        };




        request.onreadystatechange = function () {
            if (request.status === 0) {

            }

            if (request.readyState === 4 && request.status === 201) {
                // Request completed successfully
                var response = request.responseText;
                // Process the response data here
                console.log(response);
            } else if (request.readyState === 4 && request.status !== 201) {
                // Request completed with an error
                console.log('Error: ' + request.status);
            }

            if (this.status === 201) {
                const responseText = request.response;





                // // Remove "data: " from the JSON string
                // // const jsonString = responseText.replace('data:', '');
                // // jsonString = responseText.replace(/\s/g, "")

                // // Parse the JSON data
                // const dataArray = JSON.parse(jsonString);

                // // Access the "done" property
                // const doneValue = dataArray[0].done;

                // for (let i = 0; i < dataArray.length; i++) {
                //     var check = dataArray[i].done;
                //     console.log(check)

                // }


                // console.log(responseText);
                let lastDataStreamOccuranceIndex = responseText.lastIndexOf("data:");

                let lastDataStreamOccurence = request.response.slice(lastDataStreamOccuranceIndex, responseText.length)
                let temp = parseInt(lastDataStreamOccurence.replace("data:", ""));
                setfilesProgress(temp);

                // if (lastDataStreamOccurence.replace("data:", "") !== "") {
                //     var uploadArray = JSON.parse(lastDataStreamOccurence.replace("data:", ""))

                // function getFileProgressBarNode(uploadArray, i) {

                //     let parentDiv = document.createElement("div")
                //     let childDiv = document.createElement("div")

                //     parentDiv.id = `${"file " + i.toString()} `
                //     parentDiv.className = "progress"

                //     childDiv.className = "progress-bar"
                //     childDiv.style.width = uploadArray[i].progress.percentTransferred + "%";
                //     parentDiv.appendChild(childDiv)

                //     return parentDiv
                // }

                // var trueCount = 0;

                // for (let i = 0; i < uploadArray.length; i++) {
                //     if (uploadArray[i].done) {
                //         trueCount++;
                //     }


                // console.table(uploadArray[i].progress.percentTransferred);

                // if (document.getElementById("file " + i.toString()) === null) {

                //     let fileProgressBarNode = getFileProgressBarNode(uploadArray, i)

                //     document.getElementById("uploadArea").appendChild(fileProgressBarNode)
                //     document.getElementById("uploadArea").appendChild(document.createElement("br"))
                // }

                // else if (document.getElementById("file " + i.toString()) !== null) {

                //     let fileProgressBarNode = document.getElementById("file " + i.toString())
                //     fileProgressBarNode.children.item(0).innerHTML = `${uploadArray[i].progress.percentTransferred} ` + "%  " + `${uploadArray[i].description} `
                //     fileProgressBarNode.children.item(0).style.width = uploadArray[i].progress.percentTransferred + "%";
                // }
                // }
                // const normalise = (value) => ((value - MIN) * 100) / (MAX - MIN);

                //     const normalize = (trueCount / uploadArray.length) * 100;
                //     setfilesProgress(normalize);
                //     console.log(`Files ${trueCount}/${uploadArray.length}`)
                // }
                configureAlert('Build Verified', 'Your game build verified for moderation', 'success');

            }
            else if (this.status === 200) {
                let responseVar = this.response;
                let imageModerationArray = JSON.parse(responseVar.replace("data:", ""))

                console.log(imageModerationArray)

                var imagelistElements = ``

                for (var imagePath of imageModerationArray.imagePathList) {

                    imagelistElements += `< li > ${imagePath}</ > `
                }

                let messageHtml = `< p > Game Build was rejected.Following file paths are in breach of our content moderation policy: </ >
                                <ul>${imagelistElements}</ul>
                                <br><br><br><br><br>
                                <p>Reason(s) for rejection: ${imageModerationArray.moderatonLabelNamesList.toString().replaceAll(',', ', ')}</p>
                                <p>Please replace or remove above files and try again.</p>
                                `

                configureAlert('Policy Violation', messageHtml, 'danger')
            }
            else if (this.status >= 400) {

                configureAlert(this.status.toString(), 'An Error occurred. Please try again.', 'danger')
            }

            DisableSubmitButton(false);
        }

        request.onerror = function (ev) {
            configureAlert("Network Timed out", 'Please check your internet connection and try again.', 'danger')
        }

        //Request Header
        request.withCredentials = true
        request.setRequestHeader("Access-Control-Allow-Origin", "*");
        request.setRequestHeader("Authorization", 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))

        let formData = new FormData();
        // formData.append('gameTitle', gameTitle);

        // formData.append('gameReleaseStatus' ,gameReleaseStatus);
        // formData.append('genreName', gameGenre);
        // formData.append( 'gameTags' , gameTags);
        // formData.append('gameUploadGame', gameUploadGame);
        // formData.append('gameUploadCover', gameUploadCover);


        // console.log(document.getElementById("genreName").value);
        formData.append("gameTitle", document.getElementById("gameTitle").value)
        formData.append("genreName", document.getElementById("genreName").value)
        formData.append('gameDescription', encodeURI(gameDescription));
        formData.append("gameUploadCover", document.getElementById("gameUploadCover").files[0])
        formData.append("gameUploadVideo", document.getElementById("gameUploadVideo").files[0])
        console.log(document.getElementById("gameUploadCover").files[0]);
        // formData.append("gameUploadScreenshots", gameUploadScreenshots);



        let gameFilesInput = document.getElementById("gameUploadGame").files;
        let gameScreenshotInput = document.getElementById("gameUploadScreenshots").files;

        console.log(gameFilesInput)
        for (const gameFile of gameFilesInput)
            formData.append("gameUploadGame", gameFile)


        for (const gameScreen of gameScreenshotInput)
            formData.append("gameUploadScreenshots", gameScreen)


        // let gameFilesInput = document.getElementById("gameUploadScreenshots").files;

        // for (const gameFile of gameFilesInput)
        //     formData.append("gameUploadGame", gameFile)


        console.log(formData.get("gameUploadScreenshots"));
        request.send(formData);


        event.preventDefault();
    }


    const saveBuild = event => {
        configureAlert('Verifying Build', 'Please wait while we verify your game build for moderation. This may take a few minutes.', 'info');

        let request = new XMLHttpRequest();
        request.open("POST", API_BASE_URL + "/game/upload", true)


        request.onreadystatechange = function () {
            if (this.status === 201) {
                const responseText = request.response;
                let lastDataStreamOccuranceIndex = responseText.lastIndexOf("data:");
                let lastDataStreamOccurence = request.response.slice(lastDataStreamOccuranceIndex, responseText.length)

                if (lastDataStreamOccurence.replace("data:", "") !== "") {
                    var uploadArray = JSON.parse(lastDataStreamOccurence.replace("data:", ""))

                    function getFileProgressBarNode(uploadArray, i) {

                        let parentDiv = document.createElement("div")
                        let childDiv = document.createElement("div")

                        parentDiv.id = `${"file " + i.toString()}`
                        parentDiv.className = "progress"

                        childDiv.className = "progress-bar"
                        childDiv.style.width = uploadArray[i].progress.percentTransferred + "%";
                        parentDiv.appendChild(childDiv)

                        return parentDiv
                    }

                    for (let i = 0; i < uploadArray.length; i++) {

                        console.table(uploadArray[i].progress.percentTransferred);

                        if (document.getElementById("file " + i.toString()) === null) {

                            let fileProgressBarNode = getFileProgressBarNode(uploadArray, i)

                            document.getElementById("uploadArea").appendChild(fileProgressBarNode)
                            document.getElementById("uploadArea").appendChild(document.createElement("br"))
                        }

                        else if (document.getElementById("file " + i.toString()) !== null) {

                            let fileProgressBarNode = document.getElementById("file " + i.toString())
                            fileProgressBarNode.children.item(0).innerHTML = `${uploadArray[i].progress.percentTransferred}` + "%  " + `${uploadArray[i].description}`
                            fileProgressBarNode.children.item(0).style.width = uploadArray[i].progress.percentTransferred + "%";
                        }
                    }
                }
                configureAlert('Build Verified', 'Your game build verified for moderation', 'success');
            }
            else if (this.status === 200) {
                let responseVar = this.response;
                let imageModerationArray = JSON.parse(responseVar.replace("data:", ""))

                console.log(imageModerationArray)

                var imagelistElements = ``

                for (var imagePath of imageModerationArray.imagePathList) {

                    imagelistElements += `<li>${imagePath}</li>`
                }

                let messageHtml = `<p>Game Build was rejected. Following file paths are in breach of our content moderation policy: </p>
                                <ul>${imagelistElements}</ul>
                                <br><br><br><br><br>
                                <p>Reason(s) for rejection: ${imageModerationArray.moderatonLabelNamesList.toString().replaceAll(',', ', ')}</p>
                                <p>Please replace or remove above files and try again.</p>
                                `

                configureAlert('Policy Violation', messageHtml, 'danger')
            }
            else if (this.status >= 400) {

                configureAlert(this.status.toString(), 'An Error occurred. Please try again.', 'danger')
            }
        }

        request.onerror = function (ev) {
            console.log(ev)
        }

        request.withCredentials = true
        request.setRequestHeader("Access-Control-Allow-Origin", "*")

        request.setRequestHeader("Authorization", 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))

        let formData = new FormData();

        formData.append("gameId", id)

        let gameFilesInput = document.getElementById("gameUploadGame").files;

        for (const gameFile of gameFilesInput) {

            formData.append("gameUploadGame", gameFile)

        }


        request.send(formData);

        event.preventDefault();
    }

    const save = event => {


        configureAlert('Processing', ' ', 'success');

        let request = new XMLHttpRequest();


        //Meta Data Update API Call
        request.open("POST", API_BASE_URL + "/game/update", true)

        request.onreadystatechange = function () {

            if (this.status === 200) {
                configureAlert(this.status.toString(), "UPDATED SUCCESSFULLY", 'success')

                if (gameUploadGame != null) {
                    saveBuild();
                }
                else {
                    Alert.success("Game Meta Data Updated");
                    props.history.push('/mypanel');
                }

            }

            else if (this.status === 204) {

                configureAlert(this.status.toString(), 'NO CONTENT FOUND', 'success')


            }

            else if (this.status >= 400) {

                configureAlert(this.status.toString(), 'Fill the Required', 'danger')
            }

        }

        request.onerror = function (ev) {
            console.log(ev)
        }
        request.withCredentials = true
        request.setRequestHeader("Access-Control-Allow-Origin", "*")

        request.setRequestHeader("Authorization", 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))


        let formData = new FormData();


        formData.append("gameId", gameToModify.gameId)

        formData.append("gameTitle", document.getElementById("gameTitle").value)
        formData.append('gameDescription', encodeURI(gameDescription));
        formData.append("genreName", document.getElementById("genreName").value)


        request.send(formData)



    }





    return (
        Output()
    );
}


export default UploadGame;





{/* <div className='cover-div cover-div-border text-center bg-dark'>
                                
                                <label htmlFor='gameUploadCover' className='uplBtn'> Upload Cover Image
                                    <input accept='image/*' onChange={event => OnImageUpload(event)} name='gameUploadCover' id='gameUploadCover' type="file" size="60" />
                                </label>

                                <img id='gameUploadImg' src={gameUploadCover} />


                            </div>

                            <div className="text-center mt-3">
                                
                                Required (Minimum: 315x250, Recommended: 630x500)
            

                            </div> */}






{/* <div className="form-group text-white mb-4">
                <label htmlFor="access">Visibility & Access</label><br />
                <small className='text-white-50'>Use Draft to review your page before making it public. </small>


                <div className="radio">
                    <label>
                        <input onChange={event => setGameVisibility(event.target.value)} value="Draft" type="radio" name="access" id="access" />Draft<br />
                        <input onChange={event => setGameVisibility(event.target.value)} value="Restricted" type="radio" name="access" id="access" />Restricted<br />
                        <input onChange={event => setGameVisibility(event.target.value)} value="Public" type="radio" name="access" id="access" />Public                       </label>
                </div>

            </div>


            <div className="form-group text-white mb-4">
                <label htmlFor="audience">Audience</label><br />
                <small className='text-white-50'>Regardless of your location, you're legally required to comply with the Children's Online Privacy Protection Act (COPPA) and/or other laws. You're required to tell us whether your videos are 'Made htmlFor Kids'. </small>


                <div className="radio">
                    <label>
                        <input onChange={event => setGameAudience("kids")} type="radio" name="audience" id="audience" value="kids" /> Yes,it's "Made For Kids" <br />
                        <input onChange={event => setGameAudience("nokids")} type="radio" name="audience" id="audience" value="nokids" /> No, it's not "Made For Kids" <br />
                    </label>
                </div>

            </div> */}


{/* <div className='w-100 p-4  rounded-4 mt-2 primary-bg'>
                                <div id='videoBox'>
                                   

                                </div>




                                <label htmlFor='VideoUpload' className='w-100 text-center'> Upload Video
                                    <input accept='video/*' onChange={event => OnVideoUpload(event)} name='videoUpload' id='videoUpload' type="file" size="60" />
                                </label>
                            </div> */}