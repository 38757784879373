import { API_BASE_URL } from "../../Constants"
import { api, apiHeader } from "../../Configs/axiosConfig"
import { defineCancelApiObject } from "../../Configs/axiosUtils"

export const PaginationAPI = {
    recommendNextGames: async function (recommendId, count, cancel = false) {
        const response = await api.request({
            url: "/pagination/games/" + recommendId + "/" + count,
            method: "GET",

            // params: {
            //     recommendId: recommendId,
            //     count: count
            // },

            withCredentials: true,
            headers: apiHeader,

            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response.data;
    },

    recommendNextPanels: async function (count, cancel = false) {
        const response = await api.request({
            url: "/gameRecommendation/popular",
            method: "POST",

            params: {
                count: count,
            },

            headers: apiHeader,

            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response.data;
    },

    // recommendNextGenres: async function (count, cancel = false) {
    //     const response = await api.request({
    //         url: "/gameRecommendation/subscribed",
    //         method: "POST",

    //         params: {
    //             count: count,
    //         },

    //         headers: apiHeader,

    //         // retrieving the signal value by using the property name
    //         signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    //     })

    //     // returning the Game returned by the API
    //     return response.data;
    // },
    // recommendSimilarGames: async function (gameId, count, cancel = false) {
    //     const response = await api.request({
    //         url: "/gameRecommendation/similar",
    //         method: "POST",

    //         params: {
    //             gameId: gameId,
    //             count: count,

    //         },

    //         headers: apiHeader,

    //         // retrieving the signal value by using the property name
    //         signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    //     })

    //     // returning the Game returned by the API
    //     return response.data;
    // },



}

// defining the cancel API object for PaginationAPI
const cancelApiObject = defineCancelApiObject(PaginationAPI)